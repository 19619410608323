import React from 'react';
import classes from './PdfCarousel.module.css';
import pdfIcon from '../PdfCarousel/pdf-icon.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PdfCarousel = ({ items = [], narrations = [], onImageSelect }) => {
    const handleDragStart = (e) => e.preventDefault();

    const urls = items.map(pdf => pdf['o:original_url']);

    console.log("items", items);
    console.log("Narrations", narrations);

    const handlePdfClick = (id) => {
        const selectedImage = items.find(item => item.id === id);
        onImageSelect(selectedImage);
    }


    const carouselItems = items.map((item, index) => {
        const title = item.title;
        const pdfUrl = item.url;
        //const thumbnailUrl = item['thumbnail_display_urls']['medium'];

        return (
            <div key={index} className={classes.containerPdf} onClick={() => handlePdfClick(item.id)}>
                <a className={classes.aContainerPdf} href={pdfUrl} target="_blank" rel="noopener noreferrer">
                    <img src={pdfIcon} alt={title}/>
                </a>
                <p style={{wordWrap: 'break-word', width: "250px"}}>{title}</p>
            </div>
        );
    });

    const settings = {
        className: "",
        dots: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    return (
        <div className="slider-container">
            <Slider {...settings} className="my-slider">
                {carouselItems}
            </Slider>
        </div>
    );
};

export default PdfCarousel;