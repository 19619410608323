import React from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { foreignObjectBorders } from "../Graphs/NetworkGraph/CustomNode/CustomNode";

import Network from "../Network/Network";

import classes from './NarrationsNetwork.module.css';

const getCustomNodeHeight = (data) => {
  const width = getCustomNodeWidth();
  const el = (<CustomNodeContent data={data} width={width} />);
  const div = document.createElement("div");
  const htmlString = ReactDOMServer.renderToStaticMarkup(el);
  div.innerHTML = htmlString;
  document.body.appendChild(div);
  const height = div.clientHeight;
  document.body.removeChild(div);
  return height + 2*foreignObjectBorders.y;
}

const getCustomNodeWidth = () => {
  return 450;
}

const CustomNodeContent = (props) => {

  const {
    id,
    width,
    selected,
    hovered,
    data
  } = props;

  const ref = React.createRef();
  const [isLongText, setIsLongText] = React.useState(false);

  /*React.useEffect(() => {
    if (ref.current.clientHeight < ref.current.scrollHeight) {
      setIsLongText(true);
    }
  }, [ref]);*/
  
  return (
    <div id={id} data-hover={selected || hovered} className={classes.NodeContainer} style={{ width: width ? width : '100%' }}>
      <p className={classes.Label}>{data ? data.label : "-"}</p>
      <p ref={ref} className={[classes.Text, isLongText ? classes.OverflowText : ''].join(" ")}>{ data ? data.txt : "-" }</p>
    </div>
  );
};

const NarrationsNetwork = (props) => {
  
  const {
    hoveredNarration,
    narrations,
    knowledge,
    onSelectNarration,
    onDeselectNarration,
    narrationsFounded,
    filterNarrations
  } = props;

  const { t } = useTranslation();

  const [nodes, setNodes] = React.useState([]);
  const [edges, setEdges] = React.useState([]);
  const [narrationsLinks, setNarrationsLinks] = React.useState([]);
  const [narrationToFilter, setNarrationToFilter] = React.useState([]);

  const manageNarrations = React.useCallback((narrationsArray) => {
    
    const items = [];
    for(const narr of narrationsArray){
      
      let narrKnowledgeEncoding = []
      for(const f of narr.fragments){
        if(f.knowledgeEncoding.length > 0){
          narrKnowledgeEncoding = narrKnowledgeEncoding.concat(f.knowledgeEncoding);
        }
      }
      items.push({
        ...narr,
        narrKnowledgeEncoding: narrKnowledgeEncoding
      })
    }

    return items;

  }, []);

  const getNotManagedNodes = React.useCallback((narrationsArray, nodesGot) => {
    const nodesGotIds = nodesGot.map(el => { return el.id });
    return narrationsArray.filter(el => { return !nodesGotIds.includes(el.id) });
  }, []);
  
  const getNextLevelElement = React.useCallback((nodesByLevel, level) => {
    
    const currentLevelNodes = nodesByLevel[level-1];
    const managedNodes = nodesByLevel.reduce((accumulator, currentValue) => {
      const currentValueIds = currentValue.map(el => { return el.id });
      const currentValueNarrations = narrationsLinks.filter(el => { return currentValueIds.includes(el.id) });
      return accumulator.concat(currentValueNarrations);
    }, []);
    const notManagedNodes = getNotManagedNodes(narrationsLinks, managedNodes);
    
    const nextLevelNodes = [];

    for(const currNarr of currentLevelNodes){
      
      for(const nextNarr of notManagedNodes){

        const commonKnowledge = nextNarr.narrKnowledgeEncoding.filter(e => currNarr.narrKnowledgeEncoding.includes(e));

        if(commonKnowledge.length > 0){
          nextLevelNodes.push(nextNarr);
        }

      }
      
    }

    return nextLevelNodes.reduce((accumulator, currentValue) => {
      const accumulatorIds = accumulator.map(el => { return el.id });
      if(!accumulatorIds.includes(currentValue.id)){
        return accumulator.concat(currentValue);
      }
      else{
        return accumulator;
      }
    }, []);

  }, [getNotManagedNodes, narrationsLinks]);
  
  const getNarrationArray = React.useCallback(() => {
    const deepLevel = 1;
    
    if(narrationToFilter.length > 0){
      const items = [[...narrationsLinks.filter(el => { return narrationToFilter.includes(el.id) })]];
      
      for(let level=1; level<=deepLevel; level++){
        const nextLevelNodes = getNextLevelElement(items, level);
        if(nextLevelNodes.length > 0){
          items.push(nextLevelNodes);
        }
      }
      
      const itemsIds = items.reduce((accumulator, currentValue) => {
        const currentValueIds = currentValue.map(el => { return el.id });
        return accumulator.concat(currentValueIds);
      }, []);

      const resultArray = narrationsLinks
        .filter(narr => { return itemsIds.includes(narr.id) })
        .map(el => { return {...el} });
      
      for(const narrId of narrationToFilter){
        const index = resultArray.findIndex(el => { return el.id ===narrId });
        if(index !== -1){
          resultArray[index].selected = true;
        }
      }
      return resultArray;
    }
    else if(filterNarrations){
      const narrationsFoundedIds = narrationsFounded.map(el => { return el.id });
      
      const items = [[...narrationsLinks.filter(el => { return narrationsFoundedIds.includes(el.id) })]];
      
      for(let level=1; level<=deepLevel; level++){
        const nextLevelNodes = getNextLevelElement(items, level);
        if(nextLevelNodes.length > 0){
          items.push(nextLevelNodes);
        }
      }
      
      const itemsIds = items.reduce((accumulator, currentValue) => {
        const currentValueIds = currentValue.map(el => { return el.id });
        return accumulator.concat(currentValueIds);
      }, []);

      return narrationsLinks.filter(narr => { return itemsIds.includes(narr.id) });
    }
    else{
      return narrationsLinks;
    }
  }, [filterNarrations, narrationsFounded, narrationsLinks, narrationToFilter, getNextLevelElement]);

  const manageNarrationToFilter = React.useCallback((node=null) => {
    if(!narrationToFilter || narrationToFilter.includes(node.id)){
      setNarrationToFilter([]);
    }
    else{
      setNarrationToFilter([node.id]);
    }
  }, [narrationToFilter]);

  React.useEffect(() => {
    setNarrationsLinks(manageNarrations(narrations));
  }, [narrations, manageNarrations]);
  
  //set nodes
  React.useEffect(() => {
    const items = [];
    const narrationsArray = getNarrationArray();
    for(const narr of narrationsArray){
      const label = t('general.narration');
      const data = {
        label: label,
        txt: narr.text
      }
      const item = {
        id: narr.id,
        selected: narr.selected ? narr.selected : false,
        height: getCustomNodeHeight(data),
        width: getCustomNodeWidth(),
        data: data
      };
      items.push(item);
    }
    setNodes(items);

  }, [getNarrationArray, t]);

  //set edges
  React.useEffect(() => {
    const items = [];
    const narrationsArray = getNarrationArray();
    console.log("narrationsArray", narrationsArray);
    for(let i=0; i<narrationsArray.length -1; i++){
      
      const narr = narrationsArray[i];
      
      for(let j=i+1; j<narrationsArray.length; j++){
        const nestedNarr = narrationsArray[j];
        
        const commonKnowledge = narr.narrKnowledgeEncoding.filter( e => nestedNarr.narrKnowledgeEncoding.includes(e));

        if(commonKnowledge.length > 0){
          const item = {
            id: `${narr.id}-${nestedNarr.id}`,
            from: narr.id,
            to: nestedNarr.id,
            data: JSON.stringify(commonKnowledge)
          };
          items.push(item);
        }

      }
      
      
    }
    setEdges(items);

  }, [getNarrationArray]);

  const renderPopup = React.useCallback((popup) => {

    const data = JSON.parse(popup.data);

    return (
      <div style={{ position: 'absolute', left: popup.position.x, top: popup.position.y, backgroundColor: 'var(--primary-light)', transform: 'translate(-50%, -50%)', pointerEvents: 'none', margin: 0, padding: '.5rem', borderRadius: '.5rem' }}>
        { data.map((el, index) => {
          const knowledgeFilteredData = knowledge.filter(knEl => { return knEl.id === el });
          const knowledgeData = knowledgeFilteredData && knowledgeFilteredData.length > 0 ? knowledgeFilteredData[0] : null;
          return knowledgeData ? (
            <div key={"popup-key-"+el} style={index < data.length-1 ? { marginBottom: '.5rem' } : {}}>
              <p style={{ marginTop: 0, marginBottom: '.25rem', textTransform: 'capitalize', color: 'var(--text)', fontSize: 'x-small', fontWeight: 'bold' }}>{t('general.entities.'+knowledgeData.label)}</p>
              <p style={{ margin: 0, color: 'var(--primary)', fontSize: 'smaller' }}>{knowledgeData.text}</p>
            </div>
          ) : null
        }) }
      </div>
    )
  }, [knowledge, t]);

  return (
    <div className={classes.Wrapper}>
      <Network
        nodes={nodes}
        edges={edges}
        renderPopup={(data) => renderPopup(data)}
        onNodeClick={(node) => manageNarrationToFilter(node)}
        onNodeDoubleClick={(node) => onSelectNarration(node)}
        arrow={null}
        NodeComponent={CustomNodeContent}
        hoveredNarration={hoveredNarration}
      />
    </div>
  );
};

export default NarrationsNetwork;