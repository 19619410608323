import React from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { similarityAlgorithm } from "../../utils/similarityAlgorithm";
import { getCommentFromClassOrProperty } from "../../utils/omekaSManager";

import InsertedEntities from "../InsertedEntities/InsertedEntities";
import Popup from "../Popup/Popup";

import classes from './AddKnowledge.module.css';
import LeafletMap from "../LeafletMap/LeafletMap";
import LatLngRow from "../LatLngRow/LatLngRow";

const sortEntitiesBySimilarity = (a,b) => {
  if(a.perc < b.perc){
    return -1;
  }
  else if(a.perc > b.perc){
    return 1;
  }
  else{
    return 0;
  } 
}

const sortEntitiesByAnchorOffset = (a,b) => {
  if(a.selection.anchorOffset < b.selection.anchorOffset){
    return -1;
  }
  else if(a.selection.anchorOffset > b.selection.anchorOffset){
    return 1;
  }
  else{
    return 0;
  } 
}

function getSelectionOffsetRelativeTo(parentElement, currentNode){

  let currentSelection;
  let currentRange;
  let offset = 0;
  let prevSibling;
  let nodeContent;
  
  if (!currentNode){
    currentSelection = window.getSelection();
    currentRange = currentSelection.getRangeAt(0);
    currentNode = currentRange.startContainer;
    offset += currentRange.startOffset;
  }
    
  if (currentNode === parentElement){
    return offset;
  }
  
  if (!parentElement.contains(currentNode)){
    return -1;
  }
  
  prevSibling = (prevSibling  || currentNode).previousSibling;
  while ( prevSibling ){
    nodeContent = prevSibling.innerText || prevSibling.nodeValue || "";
    offset += nodeContent.length;
    prevSibling = (prevSibling  || currentNode).previousSibling
  }
  
  return offset + getSelectionOffsetRelativeTo( parentElement, currentNode.parentNode );

}

const AddKnowledge = React.forwardRef((props, ref) => {
  
  const {
    fragments,
    entities,
    existingEntities,
    insertedEntities,
    onInsertEntity,
    onRemoveEntity,
    omekaSClasses,
  } = props;

  const { t } = useTranslation();

  const [similarEntities, setSimilarEntities] = React.useState([]);
  const [selectedEntity, setSelectedEntity] = React.useState();
  const [currentSelection, setCurrentSelection] = React.useState();
  const [hoveredEntity, setHoveredEntity] = React.useState();
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupStep, setPopupStep] = React.useState();
  const [customKnowledgeText, setCustomKnowledgeText] = React.useState();
  const [customKnowledgeFragment, setCustomKnowledgeFragment] = React.useState(); 
  const [existingEntitySelected, setExistingEntitySelected] = React.useState();
  const [searchValue, setSearchValue] = React.useState("");

  //Modiifca entità
  const [entityBeingEdited, setEntityBeingEdited] = React.useState(null);
  const [editedText, setEditedText] = React.useState("");

  const handleEditEntity = (entity) => {
    setEntityBeingEdited(entity);
    setEditedText(entity.selection.text);
  };

  const handleSaveEditedEntity = () => {
    const updatedEntities = similarEntities.map((se) => {
      if (se.uuid === entityBeingEdited.uuid) {
        return { ...se, selection: { ...se.selection, text: editedText } };
      }
      return se;
    });
    setSimilarEntities(updatedEntities);
    setEntityBeingEdited(null);
    setEditedText("");
  };

  const getSimilarExistingEntities = React.useCallback((text) => {
    let similarEntitiesArray = [];
    for(const e of existingEntities){
      const similarityPercentage = similarityAlgorithm(text, e.selection.text);
      if(similarityPercentage > 0){
        similarEntitiesArray.push({
          perc: similarityPercentage,
          entity: e
        });
      }
    }
    const sortedEntities = similarEntitiesArray.sort(sortEntitiesBySimilarity).reverse();
    return sortedEntities.map(obj => obj.entity);
  }, [existingEntities]);

  const handleExistingEntitySelection = React.useCallback((entity) => {
    if(!existingEntitySelected){
      setExistingEntitySelected(entity);
    }
    else{
      if(existingEntitySelected.uuid === entity.uuid){
        setExistingEntitySelected();
      }
      else{
        setExistingEntitySelected(entity);
      }
    }
  }, [existingEntitySelected]);
  
  const handleAddCustomKnowledge = React.useCallback(() => {
    const selectionObject = {
      anchorOffset: null,
      focusOffset: null,
      text: customKnowledgeText
    }
    setCurrentSelection({ 
      fragmentUuid: customKnowledgeFragment,
      selection: selectionObject,
      locations: []
    });
    const similarExistingEntities = getSimilarExistingEntities(customKnowledgeText);
    if(similarExistingEntities.length > 0){
      setSimilarEntities(similarExistingEntities);
      setPopupStep(1);
    }
    else{
      setSimilarEntities([]);
      setExistingEntitySelected();
      setPopupStep(2);
    }
  }, [customKnowledgeText, customKnowledgeFragment, getSimilarExistingEntities]);
  
  const handleClosePopup = React.useCallback(() => {
    setSelectedEntity();
    setCurrentSelection();
    setExistingEntitySelected();
    setCustomKnowledgeFragment();
    setCustomKnowledgeText();
    setPopupOpen(false);
  }, []);
  
  const handleCustomKnowledgeText = React.useCallback((event) => {
    setCustomKnowledgeText(event.target.value);
  }, []);
  
  const handleCustomKnowledgeFragment = React.useCallback((uuid) => {
    setCustomKnowledgeFragment(uuid);
  }, []);

  const getSelectionData = React.useCallback((selection) => {
    const isBackwardSelection = selection.anchorOffset > selection.focusOffset;
    const focusNodeFragmentUuid = selection.focusNode.parentElement.parentElement.getAttribute("selectionarea");
    const anchorNodeFragmentUuid = selection.anchorNode.parentElement.parentElement.getAttribute("selectionarea");
    if(selection.type === "Range" && selection.anchorNode === selection.focusNode && anchorNodeFragmentUuid && anchorNodeFragmentUuid === focusNodeFragmentUuid){
      const startIndex = isBackwardSelection ? selection.focusOffset : selection.anchorOffset;
      const endIndex = isBackwardSelection ? selection.anchorOffset : selection.focusOffset;
      const startFragmentUuid = isBackwardSelection ? focusNodeFragmentUuid : anchorNodeFragmentUuid;
      const endFragmentUuid = isBackwardSelection ? anchorNodeFragmentUuid: focusNodeFragmentUuid;
      const startOffset = getSelectionOffsetRelativeTo(document.getElementById("p-knowledge-fragment-"+startFragmentUuid));
      const endOffset = startOffset + (endIndex - startIndex);
      return {
        startOffset: startOffset,
        endOffset: endOffset,
        startFragmentUuid: startFragmentUuid,
        endFragmentUuid: endFragmentUuid
      }
    }
    else {
      return null;
    }
  }, []);
  
  const handleSelection = React.useCallback(() => {
    if(!popupOpen){
      const selection = document.getSelection();
      const selData = getSelectionData(selection);
      //console.log(selData);
      if(selData){
        const selectionObject = {
          anchorOffset: selData.startOffset,
          focusOffset: selData.endOffset,
          text: selection.toString()
        }
        setCurrentSelection({
          fragmentUuid: selData.startFragmentUuid,
          selection: selectionObject,
          locations: []
        });
      }
      else{
        const selection = document.getSelection();
        const entityArea = selection.focusNode.parentElement.getAttribute("entityarea");
        if(!entityArea){
          setCurrentSelection();
        }
      }
    }
  }, [popupOpen]);

  const handleAddEntity = React.useCallback(() => {
    if(fragments.length > 0){
      if(currentSelection){
        const newEntity = {};
        if(existingEntitySelected){
          newEntity.id = existingEntitySelected.id;
          newEntity.uuid = existingEntitySelected.uuid;
          newEntity.entity = existingEntitySelected.entity;// selectedEntity;
          newEntity.fragmentUuid = currentSelection.fragmentUuid;
          newEntity.selection = {
            anchorOffset: null,//currentSelection.selection.anchorOffset,
            focusOffset: null,//currentSelection.selection.focusOffset,
            text: existingEntitySelected.selection.text
          };
          newEntity.locations = existingEntitySelected.locations;
        }
        else{
          newEntity.uuid = v4();
          newEntity.entity = selectedEntity;
          newEntity.fragmentUuid = currentSelection.fragmentUuid;
          newEntity.selection = currentSelection.selection;
          newEntity.locations = currentSelection.locations
        }
        onInsertEntity(newEntity);
        handleClosePopup();
      }
    }
  }, [fragments, currentSelection, onInsertEntity, handleClosePopup, selectedEntity, existingEntitySelected]);

  const handleAddKnowledge = React.useCallback((entity) => {
    console.log("handleAddKnowledge", entity);
    if(fragments.length > 0){
      setSelectedEntity(entity);
      setPopupOpen(true);
      if(currentSelection){
        const similarExistingEntities = getSimilarExistingEntities(currentSelection.selection.text);
        if(similarExistingEntities.length > 0){
          setSimilarEntities(similarExistingEntities);
          setPopupStep(1);
        }
        else {
          setSimilarEntities([]);
          setExistingEntitySelected();
          setPopupStep(2);
        }
      }
      else{
        setSimilarEntities([]);
        setExistingEntitySelected();
        setPopupStep(0);
      }
    }
  }, [fragments, currentSelection, getSimilarExistingEntities]);

  const formatTextBasedOnEntity = React.useCallback((f) => {
    const fragment = f.fragment;
    const uuid = f.uuid;
    const text = f.text;
    const items = insertedEntities
      .filter(e => { return (e.fragmentUuid === f.uuid && e.selection && e.selection.anchorOffset != null && e.selection.focusOffset != null); })
      .sort(sortEntitiesByAnchorOffset);
    let obj = [];
    let entityIndex = 0;
    let characterIndex = 0
    while(characterIndex<text.length){
      if(entityIndex >= items.length){ //normal text
        const str = text.substring(characterIndex, text.length);
        obj.push(
          <span key={"fragment-"+fragment+"-"+uuid+"-non-mark-"+characterIndex}>
            {str}
          </span>
        );
        characterIndex+=str.length;
      }
      else if(characterIndex < items[entityIndex].selection.anchorOffset){ //normal text
        const str = text.substring(characterIndex, items[entityIndex].selection.anchorOffset);
        obj.push(
          <span key={"fragment-"+fragment+"-"+uuid+"-non-mark-"+characterIndex}>
            {str}
          </span>
        );
        characterIndex+=str.length;
      }
      else if(characterIndex === items[entityIndex].selection.anchorOffset){ //entity text
        const str = text.substring(characterIndex, items[entityIndex].selection.focusOffset);
        const spanID = "entity-"+items[entityIndex].uuid;
        obj.push(
          <span
            key={spanID}
            id={spanID}
            className={hoveredEntity === spanID ? [classes.Mark, classes.HoveredMark].join(" ") : [classes.Mark].join(" ")}
          >
            {str}
          </span>
        );
        characterIndex+=str.length;
        entityIndex++;
      }
      else{}
    }
    return (<div selectionarea={uuid}>{obj}</div>);
  }, [hoveredEntity, insertedEntities]);

  const getFragmentName = React.useCallback((uuid) => {
    for(const f of fragments){
      if(f.uuid === uuid){
        return f.fragment;
      }
    }

    return ''
  }, [fragments]);

  const editLocation = React.useCallback((location, index) => {
    const entity = existingEntitySelected ? { ...existingEntitySelected } : { ...currentSelection };
    const newLocations = [ ...entity.locations];
    newLocations[index] = {
      coordinates: {
        lat: location.lat,
        lng: location.lng
      }
    };
    const newEnt = {
      ...entity,
      locations: [...newLocations]
    };
    if(existingEntitySelected){
      setExistingEntitySelected(newEnt);
    }
    else {
      setCurrentSelection(newEnt);
    }
  }, [existingEntitySelected, currentSelection]);
  
  const addLocation = React.useCallback((location) => {
    if(existingEntitySelected && existingEntitySelected.locations && existingEntitySelected.locations.length === 1){
      editLocation(location, 0);
    }
    else if(currentSelection && currentSelection.locations && currentSelection.locations.length === 1){
      editLocation(location, 0);
    }
    else{
      const entity = existingEntitySelected ? { ...existingEntitySelected } : { ...currentSelection };
      const newLocations = [ ...entity.locations];
      newLocations.push({
        coordinates: {
          lat: location.lat,
          lng: location.lng
        }
      });
      const newEnt = {
        ...entity,
        locations: [...newLocations]
      };
      if(existingEntitySelected){
        setExistingEntitySelected(newEnt);
      }
      else {
        setCurrentSelection(newEnt);
      }
    }
  }, [existingEntitySelected, currentSelection, editLocation]);

  const deleteLocation = React.useCallback((index) => {
    const entity = existingEntitySelected ? { ...existingEntitySelected } : { ...currentSelection };
    const newLocations = [ ...entity.locations];
    newLocations.splice(index,1);
    const newEnt = {
      ...entity,
      locations: [...newLocations]
    };
    if(existingEntitySelected){
      setExistingEntitySelected(newEnt);
    }
    else {
      setCurrentSelection(newEnt);
    }
  }, [existingEntitySelected, currentSelection]);

  const groupedEntities = similarEntities.reduce((groups, se) => {
    const key = se.entity;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(se);
    return groups;
  }, {});

  const filteredEntities = entities.filter(se =>
      se.selection && (se.selection.text.toLowerCase().startsWith(searchValue.toLowerCase()) ||
          se.selection.text.toLowerCase().includes(searchValue.toLowerCase()))
  );

  const editEntity = React.useCallback((index, entity) => {

  });

  React.useEffect(() => {
    document.addEventListener('selectionchange', handleSelection);
    return () => {
      document.removeEventListener('selectionchange', handleSelection);
    }
  }, [handleSelection]);

  return (
      <div ref={ref} className={classes.Container}>
        <div className={classes.InfoContainer}>
          <p className={classes.InfoTitle}>{t('author.addKnowledge.add')}</p>
          <p className={[classes.InfoSubTitle, classes.Info].join(" ")}>{t('author.addKnowledge.defineEntities')}</p>
          <p className={classes.InfoText}>{t('author.addKnowledge.textInfo1')}</p>
          <div className={classes.EntitiesBtnContainer}>
            {
              entities.map((e, index) => {
                return (
                    <p
                        entityarea="entityarea"
                        key={"entities-list-"+index}
                        className={classes.EntityBtn}
                        title={getCommentFromClassOrProperty(omekaSClasses, e.class)}
                        onClick={() => handleAddKnowledge(e.label)}
                    >{t('general.entities.'+e.label)}
                    </p>
                )
              })
            }
          </div>
          <div className={classes.InsertedEntitiesContainer}>
            <InsertedEntities
                entities={entities}
                existingEntities={existingEntities}
                insertedEntities={insertedEntities}
                setHoveredEntity={setHoveredEntity}
                onRemoveEntity={(index) => onRemoveEntity(index)}
                onEditEntity={(index, entity) => editEntity(index, entity)}
            />
          </div>
        </div>
        <div className={classes.BoxesContainer}>
          <div className={classes.Box}>
            {
                fragments.length === 0 &&
                <p className={classes.NoFragment}>{t('author.addKnowledge.addNarrationFirst')}</p>
            }
            {
                fragments.length > 0 &&
                fragments.map((f) => (
                    <div key={"knowledge-fragment-"+f.uuid} className={classes.KnowledgeFragment}>
                      <p className={classes.FragmentTitle}>{t('general.fragments.'+f.fragment)}</p>
                      <div
                          id={"p-knowledge-fragment-"+f.uuid}
                          className={classes.FragmentText}
                      >
                        {formatTextBasedOnEntity(f)}
                      </div>
                    </div>
                ))
            }
          </div>
        </div>
        {
            popupOpen &&
            <Popup width="60%">
              {
                  popupStep === 0 &&
                  <div className={classes.PopupStep}>
                    <p className={classes.PopupTitle}>{t('author.addKnowledge.addCustomTextKnowledge')}</p>
                    <textarea
                        className={classes.PopupTextArea}
                        rows={2}
                        type="text"
                        //value={text}
                        placeholder={t("author.addKnowledge.addKnowledgeTextPlaceholder", { context: "capFirst" })}
                        onChange={(event) => handleCustomKnowledgeText(event)}
                    />
                    <p className={classes.SelectFragment}>{t('author.addKnowledge.selectInsertedFragment')}</p>
                    <div className={classes.InsertedFragmentsNamesContainer}>
                      { fragments.map(f => {
                        return (
                            <p
                                key={"add-custom-knowledge-fragment-"+f.uuid}
                                className={customKnowledgeFragment === f.uuid ? [classes.InsertedFragmentName, classes.SelectedInsertedFragment].join(" ") : classes.InsertedFragmentName}
                                onClick={() => handleCustomKnowledgeFragment(f.uuid) }
                            >
                              {t('general.fragments.'+f.fragment)}
                            </p>
                        )
                      }) }
                    </div>
                    <div className={classes.PopupButtonsContainer}>
                      <button className={classes.PopupButton} onClick={() => handleClosePopup()}>
                        {t('author.addKnowledge.cancel')}
                      </button>
                      <button disabled={!customKnowledgeFragment || !customKnowledgeText} className={classes.PopupButton} onClick={() => handleAddCustomKnowledge()}>
                        {t('author.addKnowledge.continue')}
                      </button>
                    </div>
                  </div>
              }
              {
                  popupStep === 1 &&
                  <div className={classes.PopupStep}>
                    <p className={classes.PopupTitle}>{t('author.addKnowledge.addKnowledge')}</p>
                    <div className={classes.PopupStep1}>
                      <p className={classes.PopupQuestion}>{t("author.addKnowledge.similarEntitiesFound", {num: similarEntities.length})}</p>
                      <input
                          type="text"
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          placeholder="Cerca..."
                      />
                      <div className={classes.SimilarEntitiesContainer}>
                        {
                          Object.entries(groupedEntities).map(([entity, entities]) => (
                              <div key={entity}>
                                <p className={classes.PopupTextLabel}>{entity}:</p>
                                {
                                  entities
                                      .filter(se => se.selection.text.toLowerCase().includes(searchValue.toLowerCase()))
                                      .map(se => {
                                        const alreadyInsertedEntity = insertedEntities.filter(el => {
                                          return el.fragmentUuid === currentSelection.fragmentUuid && el.uuid === se.uuid
                                        }).length > 0;
                                        if (alreadyInsertedEntity) {
                                          return (
                                              <p
                                                  key={"similar-entity-" + se.uuid}
                                                  className={classes.AlreadyInsertedEntity}
                                                  title={t("general.alreadyInsertedEntity")}
                                              >{`${se.selection.text}\n(${t("general.entities." + se.entity)})`}</p>
                                          )
                                        } else {
                                          return (
                                              <div key={"similar-entity-" + se.uuid}
                                                   className={classes.EntityContainer}>
                                                <p
                                                    className={existingEntitySelected && existingEntitySelected.uuid === se.uuid ? [classes.SimilarEntityName, classes.SelectedSimilarEntity].join(" ") : classes.SimilarEntityName}
                                                    onClick={() => handleExistingEntitySelection(se)}
                                                >
                                                  {`${se.selection.text}\n(${t("general.entities." + se.entity)})`}
                                                  <span className={classes.VerticalDots}
                                                        onClick={() => handleEditEntity(se)}>⋮</span>
                                                </p>
                                              </div>
                                          )
                                        }
                                      })
                                }
                              </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className={classes.PopupButtonsContainer}>
                      <button className={classes.PopupButton} onClick={() => handleClosePopup()}>
                      {t('author.addKnowledge.cancel')}
                      </button>
                      <button className={classes.PopupButton} onClick={() => setPopupStep(2)}>
                        {t('author.addKnowledge.continue')}
                      </button>
                    </div>
                  </div>
              }
              {
                  entityBeingEdited && (
                      <div className={classes.EditPopup}>
                        <p><strong>Modifica l'entità</strong></p>
                        <textarea
                            className={classes.Input}
                            value={editedText}
                            onChange={(e) => setEditedText(e.target.value)}
                        />
                        <button className={classes.PopupButton} onClick={handleSaveEditedEntity}>Save</button>
                        <button className={classes.PopupButton} onClick={() => setEntityBeingEdited(null)}>Cancel</button>
                      </div>
                  )
              }
              {
                  popupStep === 2 &&
                  <div className={classes.PopupStep}>
                    <p className={classes.PopupTitle}>{t('author.addKnowledge.addKnowledge')}</p>
                    <div className={classes.PopupGrid}>
                      <p className={classes.PopupTextLabel}>{t('author.addKnowledge.class')}:</p><p className={classes.PopupText}>{t('general.entities.'+(existingEntitySelected ? existingEntitySelected.entity : selectedEntity))}</p>
                      <p className={classes.PopupTextLabel}>{t('author.addKnowledge.text')}:</p><p className={classes.PopupText}>{existingEntitySelected ? existingEntitySelected.selection.text : currentSelection.selection.text}</p>
                      <p className={classes.PopupTextLabel}>{t('general.fragment')}:</p><p className={classes.PopupText}>{t('general.fragments.'+getFragmentName(currentSelection.fragmentUuid))}</p>
                    </div>
                    <div className={classes.PopupLocationContainer}>
                      <p className={classes.AddLocationText}>{t("author.addKnowledge.addLocationText")}</p>
                      <p className={classes.AddLocationText2}>{t("author.addKnowledge.addLocationText2")}:</p>
                      <div className={classes.InsertedLocations}>
                        {
                            existingEntitySelected &&
                            existingEntitySelected.locations.map((el, index) => {
                              return (
                                  <LatLngRow
                                      key={"unique-lat-long-key"+v4()}
                                      lat={el.coordinates.lat}
                                      lng={el.coordinates.lng}
                                      onEditLocation={(l) => editLocation(l, index)}
                                      onDeleteLocation={() => deleteLocation(index)}
                                  />
                              )
                            })
                        }
                        {
                            !existingEntitySelected &&
                            currentSelection.locations.map((el, index) => {
                              return (
                                  <LatLngRow
                                      key={"unique-lat-long-key"+v4()}
                                      lat={el.coordinates.lat}
                                      lng={el.coordinates.lng}
                                      onEditLocation={(l) => editLocation(l, index)}
                                      onDeleteLocation={() => deleteLocation(index)}
                                  />
                              )
                            })
                        }
                      </div>
                      <div className={classes.PopupMapContainer}>
                        <LeafletMap
                            onMapClick={(l) => addLocation(l)}
                            markers={existingEntitySelected ? existingEntitySelected.locations : currentSelection.locations}
                            zIndex={0}
                            disablePopup={true}
                            allowClickOnMapEvent={true}
                            disableFitBounds={true}
                        />
                      </div>
                    </div>
                    <div className={classes.PopupButtonsContainer}>
                      <button className={classes.PopupButton} onClick={() => handleClosePopup()}>
                        {t('author.addKnowledge.cancel')}
                      </button>
                      <button className={classes.PopupButton} onClick={() => setPopupStep(3)}>
                        {t('author.addKnowledge.continue')}
                      </button>
                    </div>
                  </div>
              }
              {
                  popupStep === 3 &&
                  <div className={classes.PopupStep}>
                    <p className={classes.PopupTitle}>{t('author.addKnowledge.addKnowledge')}</p>
                    <div className={classes.PopupGrid}>
                      <p className={classes.PopupTextLabel}>{t('author.addKnowledge.class')}:</p><p className={classes.PopupText}>{t('general.entities.'+(existingEntitySelected ? existingEntitySelected.entity : selectedEntity))}</p>
                      <p className={classes.PopupTextLabel}>{t('author.addKnowledge.text')}:</p><p className={classes.PopupText}>{existingEntitySelected ? existingEntitySelected.selection.text : currentSelection.selection.text}</p>
                      <p className={classes.PopupTextLabel}>{t('general.fragment')}:</p><p className={classes.PopupText}>{t('general.fragments.'+getFragmentName(currentSelection.fragmentUuid))}</p>
                    </div>
                    {
                        (
                            (existingEntitySelected && existingEntitySelected.locations && existingEntitySelected.locations.length > 0) ||
                            (currentSelection && currentSelection.locations && currentSelection.locations.length > 0)
                        ) &&
                        <div className={classes.PopupMapContainer}>
                          <LeafletMap
                              markers={existingEntitySelected ? existingEntitySelected.locations : currentSelection.locations}
                              zIndex={0}
                              disablePopup={true}
                              allowClickOnMapEvent={false}
                              disableInteractions={true}
                              disableFitBounds={true}
                              allowPanTo={true}
                          />
                        </div>
                    }
                    <div className={classes.PopupButtonsContainer}>
                      <button className={classes.PopupButton} onClick={() => handleClosePopup()}>
                        {t('author.addKnowledge.cancel')}
                      </button>
                      <button className={classes.PopupButton} onClick={() => handleAddEntity()}>
                        &#10004; {t('author.addKnowledge.insert', { context: existingEntitySelected ? null : "new" })}
                      </button>
                    </div>
                  </div>
              }
            </Popup>
        }
      </div>
  );

});

export default AddKnowledge;