import React from "react";
import { Canvas, Edge, MarkerArrow, Node } from 'reaflow';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { CustomNode } from "./CustomNode/CustomNode";

import classes from './NetworkGraph.module.css';
import { doubleClickDelay } from "../../../utils/constants";

const delay = doubleClickDelay;

const NetworkGraph = (props) => {

  const {
    hoveredNarration,
    nodes,
    edges,
    renderPopup,
    onNodeEnter,
    onNodeLeave,
    onNodeClick,
    onNodeDoubleClick,
    onEdgeEnter,
    onEdgeLeave,
    onEdgeClick,
    arrow,
    direction,
    panning,
    NodeComponent,
    nodeColor = "#FFFFFF",
    nodeHoverColor = "var(--primary-light)",
    edgeColor = "#A9AAAC",
    edgeHoverColor = "var(--primary)"
  } = props;

  const ref = React.useRef();

  const [wrapperReference, setWrapperReference] = React.useState(null);
  const [popup, setPopup] = React.useState({ visible: false, position: { x: 0, y: 0 }, content: null });
  const [hoveredNode, setHoveredNode] = React.useState();
  const [hoveredEdge, setHoveredEdge] = React.useState();
  const [timer, setTimer] = React.useState(null);

  const fitCanvas = React.useCallback(() => {
    if(wrapperReference && ref.current){
      wrapperReference.centerView(0.5);
    }
  }, [wrapperReference]);

  const nodeEntered = React.useCallback((node) => {
    if(onNodeEnter){
      return onNodeEnter(node);
    }
  }, [onNodeEnter]);

  const nodeLeft = React.useCallback((node) => {
    if(onNodeLeave){
      return onNodeLeave(node);
    }
  }, [onNodeLeave]);

  const nodeClicked = React.useCallback((node) => {
    if(onNodeClick){
      return onNodeClick(node);
    }
  }, [onNodeClick]);

  const nodeDoubleClicked = React.useCallback((node) => {
    if(onNodeDoubleClick){
      return onNodeDoubleClick(node);
    }
  }, [onNodeDoubleClick]);

  const edgeEntered = React.useCallback((edge) => {
    if(onEdgeEnter){
      return onEdgeEnter(edge);
    }
  }, [onEdgeEnter]);

  const edgeLeft = React.useCallback((edge) => {
    if(onEdgeLeave){
      return onEdgeLeave(edge);
    }
  }, [onEdgeLeave]);

  const edgeClicked = React.useCallback((edge) => {
    if(onEdgeClick){
      return onEdgeClick(edge);
    }
  }, [onEdgeClick]);

  const popupRendered = React.useCallback((data) => {
    if(renderPopup){
      return renderPopup(data);
    }
    else {
      return null;
    }
  }, [renderPopup]);

  const onSingleDoubleClickHandler = (node) => {
    if(timer){
      clearTimeout(timer);
      setTimer(null);
      console.log("Double Click");
      nodeDoubleClicked(node);
    }
    else{
      setTimer(
        setTimeout(() => {
          console.log("Single Click");
          nodeClicked(node);
          setTimer(null);
        }, delay * 1000)
      );
    }
  };

  const getEdgeColor = (edge) => {
    const data = edge.data || edge.properties.data;
    const colorMapping = {
      "isTimeSpanOf": "#FF0000",
      "hasObject": "#00FF00",
      "fallsWithin": "#0000FF",
      "hasCurrentPermanentLocation": "#FFFF00",
      "contains": "#FF00FF",
      "isAgentOf": "#00FFFF",
      "hasProduced": "#FFA500",
      "causes": "#800080",
      "participatingIn": "#008000",
      "isRelatingTo": "#000080",
      "hasTheme": "#808000",
      "isCurrentPermanentLocationOf": "#800000",
      "hasRecipient": "#008080",
      "isProducedBy": "#808080",
      "isMemberOf": "#C0C0C0",
      "hasAgent": "#FFD700",
      "isObjectOf": "#FF69B4",
      "hasReferenceModel": "#FF4500",
      "hasTimeSpan": "#FF6347",
      "isThemeOf": "#FF1493",
      "causedBy": "#FF00FF",
      "isReferenceModelOf": "#FF00FF",
      "isRecipientOf": "#FF00FF",
      "hasMember": "#FF00FF",
      "hadParticipant": "#FF00FF",
    };

    //console.log("Edge data", data);
    if (data && typeof data === 'string') {
      if (colorMapping[data]) {
        return colorMapping[data];
      }
      try {
        const parsedData = JSON.parse(data);
        if (parsedData.length > 0 && parsedData[0].rel) {
          const rel = parsedData[0].rel;
          return colorMapping[rel] || edgeColor;
        }
      } catch (e) {
        console.error("Invalid JSON data:", data);
      }
    }

    return edgeColor;
  };

  const getNodeColor = (node) => {
    //console.log("Node", node);
    //console.log("Edges", edges);
    const label = node.properties.data.label || node.data.label;
    if (label === "luogo") {
      return "#63089c";
    }
    return nodeColor;
  };

  React.useEffect(() => {
    //console.log("Nodes", nodes);
    fitCanvas();
  }, [fitCanvas]);

  const selectedEdge = JSON.parse(sessionStorage.getItem('selectedEdge'));
  let selectedEdgeId = null;
  let nodeToCenter = null;
  let center = { x: 0, y: 0 };
  if (selectedEdge && selectedEdge.active) {
    selectedEdgeId = selectedEdge.id;
    nodeToCenter = selectedEdge.from.toString() + "_s0"

  }

  return (
    <div className={classes.Wrapper}>
      <TransformWrapper
        wheel={{ step: 0.025 }}
        onInit={(wrappeRef) => {
            setWrapperReference(wrappeRef);
            if (selectedEdgeId) {
              center = JSON.parse(sessionStorage.getItem('center')) || { x: 0, y: 0 };
              setTimeout(() => {
                //console.log("Node to center", nodeToCenter);
                //wrappeRef.setTransform(center.x, center.y, 1, 300, 'easeOut');
                //console.log("Centering view on edge", selectedEdgeId);
                //console.log("Center", center);
                //console.log("Wrapper", wrappeRef.state);
              }, 1000);
            }
        }}
        limitToBounds={true}
        minScale={0.01}
        maxScale={100}
        panning={panning}
      >
        <TransformComponent
          wrapperStyle={{ width: '100%', height: '100%', position: 'relative' }}
        >
          {popup.visible ? popupRendered(popup) : null}
          <Canvas
            maxWidth={50 * 1000}
            maxHeight={50 * 1000}
            ref={ref}
            zoomable={false}
            readonly={true}
            nodes={nodes}
            edges={edges}
            arrow={arrow !== undefined ? arrow : <MarkerArrow style={{ fill: edgeColor }} />}
            direction={direction ? direction : "DOWN" }
            onLayoutChange={(l) => {
              if(l.width>0 && l.height>0) {
                ref.current.fitCanvas();
                fitCanvas();
              }
            }}
            edge={(edge) => {
              const isSelected = edge.id === selectedEdgeId;
              if (isSelected) {
                //TODO: trovare un modo per trovare le coordinate reali dell'edge
                center = edge.sections[0].startPoint || { x: 0, y: 0 };
                //console.log("Edge", edge);
                sessionStorage.setItem('center', JSON.stringify(center));
              }
              return (
                <Edge
                  className={hoveredEdge === edge.id ? [classes.Edge, classes.HoveredEdge].join(" ") : classes.Edge}
                  style={{ stroke: isSelected ? "#FF0000" : getEdgeColor(edge) }}
                  onEnter={(event, edge) => {
                    event.target.parentNode.childNodes[0].style.stroke = getEdgeColor(edge);
                    const newPopupData = {
                      visible: true,
                      position: {
                        x:  event.nativeEvent.offsetX,
                        y:  event.nativeEvent.offsetY
                      },
                      data: edge.data
                    }
                    console.log("Position", newPopupData.position);
                    setHoveredEdge(edge.id);
                    setPopup(newPopupData);
                    edgeEntered(edge);
                  }}
                  onLeave={(event, edge) => {
                    event.target.parentNode.childNodes[0].style.stroke = getEdgeColor(edge);
                    const newPopupData = {
                      visible: false,
                      position: {
                        x: 0,
                        y: 0
                      },
                      data: null
                    }
                    setHoveredEdge(null);
                    setPopup(newPopupData);
                    edgeLeft(edge);
                  }}
                  onClick={(event, edge) => {
                    edgeClicked(edge);
                  }}
                />
              )
            }}
            node= {(node) => {
              return (
                <Node
                  rx="1rem"
                  ry="1rem"
                  {...node}
                  className={classes.Node}
                  style={{ fill: getNodeColor(node), filter: 'drop-shadow(0 5px 10px rgba(0, 0, 0, 0.14))' }}
                  onEnter={(event, node) => {
                    //event.target.style.fill = nodeHoverColor;
                    console.log("Node", node);
                    setHoveredNode(node.id);
                    nodeEntered(node);
                  }}
                  onLeave={(event, node) => {
                    //event.target.style.fill = getNodeColor(node);
                    setHoveredNode(null);
                    nodeLeft(node);
                  }}
                  onClick={(event, node) => {
                    onSingleDoubleClickHandler(node);
                  }}

                >
                  { event => (
                      <CustomNode
                        width={event.width}
                        height={event.height}
                        id={event.node.id}
                        selected={event.node.selected}
                        hovered={hoveredNarration === event.node.id || hoveredNode === event.node.id}
                        data={event.node.data}
                        NodeComponent={NodeComponent}
                      />
                    )
                  }
                </Node>
              )
            }}
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );

};

export default NetworkGraph;