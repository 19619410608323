import React from "react";

import TopBar from "../../../components/TopBar/TopBar";
import BottomNavigation from "../../../components/BottomNavigation/BottomNavigation";
import NarrationNetwork from "../../../components/NarrationNetwork/NarrationNetwork";
import NarrationKnowledge from "../../../components/NarrationKnowledge/NarrationKnowledge";
import NarrationSources from "../../../components/NarrationSources/NarrationSources";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import classes from "./Narration.module.css";

import EDIT_ICON from '../../../assets/images/edit.svg';
import EDIT_ICON_DISABLED from '../../../assets/images/edit_disabled.svg';
import { UserContext } from "../../../contexts/UserContext";
import { roles } from "../../../utils/constants";


const vizTypes = {
  NARRATION: 'narration',
  KNOWLEDGE: 'knowledge',
  SOURCES: 'sources'
};

const buttons = [
  { label: "Narrazione", code: vizTypes.NARRATION }, 
  { label: "Knowledge", code: vizTypes.KNOWLEDGE }, 
  { label: "Fonti", code: vizTypes.SOURCES }
];

const Narration = (props) => {

  const {
    narrations,
    knowledge,
    media
  } = props;

  const { t } = useTranslation();
  const { id } = useParams();
  const { userLogged, userData } = React.useContext(UserContext);
  const navigate = useNavigate();

  const [vizType, setVizType] = React.useState(vizTypes.NARRATION);
  const [selectedNarration, setSelectedNarration] = React.useState();

  const [narrationAuthor, setNarrationAuthor] = React.useState();
  const [fragmentsNodes, setFragmentsNodes] = React.useState([]);

  const [selectedElement, setSelectedElement] = React.useState(null);
  const [selectedSourceMedia, setSelectedSourceMedia] = React.useState(null);

  const changeVizType = (newVizType) => {
    setVizType(newVizType);
  };

  const handleElementChange = (newElement) => {
    setSelectedElement(newElement);
  };

  const handleSourceMediaChange = (newSourceMedia) => {
    setSelectedSourceMedia(newSourceMedia);
  };

  React.useEffect(() => {
    if (selectedElement !== null) {
      setVizType(vizTypes.SOURCES);
    }
  }, [selectedElement]);
  
  const redirectTo = React.useCallback((url, state = null) => {
    if(state){
      navigate(url, { state: { ...state } });
    }
    else{
      navigate(url);
    }
  }, [navigate]);

  const getFragmentsOrdered = React.useCallback((fragments) => {
    const copiedFragments = [ ...fragments ];
    const startingFragmentIndex = copiedFragments.findIndex(el => { return !el.previous; });
    if(startingFragmentIndex !== -1){
      let items = [];
      let currentFragment = copiedFragments[startingFragmentIndex];
      copiedFragments.splice(startingFragmentIndex, 1);
      items.push(currentFragment);
      
      while(copiedFragments.length > 0){
        const currFragm = currentFragment;
        const fragmentIndex = copiedFragments.findIndex(el => { return el.id === currFragm.next; });
        if(fragmentIndex !== -1){
          currentFragment = copiedFragments[fragmentIndex];
          copiedFragments.splice(fragmentIndex, 1);
          items.push(currentFragment);
        }
        else{
          items = items.concat(copiedFragments);
          copiedFragments.splice(0, copiedFragments.length);
        }
      }

      return items;
    }
    else{
      return fragments;
    }
  }, []);

  const narrationEditAllowed = React.useCallback(() => {
    if(userLogged && userData && selectedNarration && (userData.role === roles.ADMINISTRATOR || userData.omekasId === selectedNarration.owner)){
      return true;
    }
    else{
      return false;
    }
  }, [userData, userLogged, selectedNarration]);

  React.useEffect(() => {
    if(selectedNarration){
      
      setNarrationAuthor(selectedNarration.narrator)
      
      const nodes = [];
      const orderedFragments = [ ...getFragmentsOrdered(selectedNarration.fragments) ];

      for(const fragment of orderedFragments){
        const id = fragment.id;
        const label = t('general.fragments.'+fragment.label);
        const txt = fragment.text;
        const sourceRefs = fragment.sourceReferences;
        nodes.push({
          id: id,
          data: {
            label: label,
            txt: txt,
            sourceRefs: sourceRefs
          }
        });
      }
      setFragmentsNodes(nodes);
    }
  }, [selectedNarration, t, getFragmentsOrdered]);

  React.useEffect(() => {
    if(id && narrations && narrations.length > 0){
      const items = narrations.filter(el => { return el.id === parseInt(id) });
      if(items.length === 1){
        setSelectedNarration(items[0]);
      }
    }
  }, [id, narrations]);

  return (
    <div className={classes.Wrapper}>
      { 
        selectedNarration && vizType === vizTypes.NARRATION &&
        <NarrationNetwork 
          selectedNarration={selectedNarration}
          narrationAuthor={narrationAuthor}
          fragmentsNodes={fragmentsNodes}
          changeVizType={setVizType}
          onElementChange={handleElementChange}
          onSourceMediaChange={handleSourceMediaChange}
        />
      }
      { 
        selectedNarration && vizType === vizTypes.KNOWLEDGE &&
        <NarrationKnowledge 
          narration={selectedNarration} 
          knowledge={knowledge}
          knowledgeLevel={3}
        />
      }
      { 
        selectedNarration && vizType === vizTypes.SOURCES && 
        <NarrationSources 
          narration={selectedNarration}
          selectedElement={selectedElement}
          selectedSourceMedia={selectedSourceMedia}
          changeVizType={changeVizType}
        />
      }
      <TopBar style={{ position: 'absolute', transform: 'translate(-50%, 0)', top: 0, left: '50%' }} isNarrationDetail={true} />
      <BottomNavigation
        style={{ position: 'absolute', transform: 'translate(-50%, 0)', bottom: '3rem', left: '50%' }}
        buttons={buttons}
        activeCode={vizType}
        change={newVt => setVizType(newVt)}
      />
      <p 
        className={classes.BackToNarrations}
        onClick={() => redirectTo("/visualization/narrations")}
      >Torna alla mappa</p>
      <img 
        src={narrationEditAllowed() ? EDIT_ICON : EDIT_ICON_DISABLED} 
        className={narrationEditAllowed() ? classes.EditArticle : classes.EditArticleDisabled} 
        alt=""
        onClick={() => redirectTo("/author/"+id)}
      />
    </div>
  );
};

export default Narration;