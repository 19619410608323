import React, { useEffect, useState } from "react";
import ImageCarousel from "../ImageCarousel/ImageCarousel";
import PdfCarousel from "../PdfCarousel/PdfCarousel";
import ImagePopup from "../ImagePopup/ImagePopup";
import { commonProperties } from "../../utils/constants";
import classes from './Sources.module.css';

const Sources = (props) => {
    const {
        narrations,
        media,
        onSelectNarration,
    } = props;

    const [sourcesByType, setSourcesByType] = useState({});
    const [imagePopupOpen, setImagePopupOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState();
    const [selectedMediaRef, setSelectedMediaRef] = useState();

    useEffect(() => {
        const sourcesMap = {};
        narrations.forEach(narration => {
            narration.fragments?.forEach(fragment => {
                fragment.sourceReferences?.forEach(sourceReference => {
                    sourceReference.sources?.forEach(source => {
                        source.media?.forEach(mediaItem => {
                            const type = source.type.split(':').pop();
                            if (!sourcesMap[type]) {
                                sourcesMap[type] = [];
                            }
                            sourcesMap[type].push(mediaItem);
                        });
                    });
                });
            });
        });
        setSourcesByType(sourcesMap);
    }, [narrations]);

    const handleImageSelect = async (media) => {
        const narrationsWithMedia = narrations.filter(narration =>
            narration.fragments?.some(fragment =>
                fragment.sourceReferences?.some(sourceReference =>
                    sourceReference.sources?.some(source =>
                        source.media?.some(mediaItem => mediaItem['o:id'] === media.id)
                    )
                )
            )
        ).map(narration => ({
            id: narration.id,
            text: narration.text
        }));

        setSelectedMedia({ ...media, narrations: narrationsWithMedia });
        setSelectedMediaRef(media);
        openMedia("image");
    };

    const openMedia = React.useCallback((type) => {
        if (type === "image") {
            setImagePopupOpen(true);
        }
    }, []);

    const closeImagePopup = React.useCallback(() => {
        setSelectedMedia();
        setImagePopupOpen(false);
    }, []);

    return (
        <div className={classes.TopContainer}>
            {Object.entries(sourcesByType).map(([type, mediaItems], index) => (
                <div key={index} className={classes.CarouselContainer}>
                    <h1 className={classes.title}>{type}</h1>
                    <div className={classes.carousel}>
                        {mediaItems[0]["o:media_type"] === "application/pdf" ? (
                            <PdfCarousel
                                items={mediaItems.map(mediaItem => ({
                                    url: mediaItem["o:original_url"],
                                    id: mediaItem["o:id"],
                                    title: mediaItem["dcterms:title"]?.[0]?.['@value'] || 'No Title',
                                    className: index === 0 ? classes.firstCarouselImage : ''
                                }))}
                                narrations={narrations}
                                onImageSelect={handleImageSelect}
                            />
                        ) : (
                            <ImageCarousel
                                images={mediaItems.map(mediaItem => ({
                                    url: mediaItem["o:original_url"],
                                    id: mediaItem["o:id"],
                                    title: mediaItem["dcterms:title"]?.[0]?.['@value'] || 'No Title',
                                    className: index === 0 ? classes.firstCarouselImage : ''
                                }))}
                                onImageSelect={handleImageSelect}
                                narrations={narrations}
                            />
                        )}
                    </div>
                </div>
            ))}
            {imagePopupOpen && (
                <ImagePopup
                    popupOpen={imagePopupOpen}
                    selectedMedia={selectedMedia}
                    commonProperties={commonProperties}
                    closePopup={closeImagePopup}
                    selectedSourceRef={selectedMediaRef}
                    navigateToNarration={onSelectNarration}
                />
            )}
        </div>
    );
}

export default Sources;