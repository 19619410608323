import React from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { getKnowledgeItem } from "../../utils/omekaSManager";
import { foreignObjectBorders } from "../Graphs/NetworkGraph/CustomNode/CustomNode";
import Network from "../Network/Network";
import classes from './EntitiesNetwork.module.css';
import CLOSE_ICON from '../../assets/images/close.svg';

const getCustomNodeHeight = (data) => {
    const width = getCustomNodeWidth();
    const el = (<CustomNodeContent data={data} width={width} />);
    const div = document.createElement("div");
    const htmlString = ReactDOMServer.renderToStaticMarkup(el);
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    const height = div.clientHeight;
    document.body.removeChild(div);
    return height + 2 * foreignObjectBorders.y*10;
}

const getCustomNodeWidth = () => {
  return 450;
}


const CustomNodeContent = (props) => {
    const { id, width, selected, hovered, data } = props;
    const ref = React.createRef();
    const [isLongText, setIsLongText] = React.useState(false);

    return (
        <div id={id} data-hover={selected || hovered} className={classes.NodeContainer} style={{ width: width ? width : '100%' }}>
            <p className={classes.Label}>{data ? data.label : "-"}</p>
            <p ref={ref} className={[classes.Text, isLongText ? classes.OverflowText : ''].join(" ")}>{data ? data.txt : "-"}</p>
        </div>
    );
};

const EntitiesNetwork = (props) => {
    const { knowledge } = props;
    const { t } = useTranslation();
    const [nodes, setNodes] = React.useState([]);
    const [edges, setEdges] = React.useState([]);

    React.useEffect(() => {
        if (!knowledge || knowledge.length === 0) {
            return;
        }

        // Extract unique labels
        const uniqueLabels = [...new Set(knowledge.map(entity => entity.label))];
        const sectorAngle = 360 / uniqueLabels.length; // Angle of each sector

        const radius = 10000; // Radius of the circle
        const centerX = 5000; // Center of the circle
        const centerY = 5000; // Center of the circle

        const nodes = knowledge.map((entity) => {
            const labelIndex = uniqueLabels.indexOf(entity.label);
            const angle = labelIndex * sectorAngle;
            const x = centerX + radius * Math.cos(angle * Math.PI / 180);
            const y = centerY + radius * Math.sin(angle * Math.PI / 180);

            return {
                id: entity.id,
                label: entity.label,
                text: entity.text,
                height: getCustomNodeHeight(entity),
                width: getCustomNodeWidth(),
                data: entity,
                position: { x, y }
            };
        });
        console.log("Nodes", nodes);

        const edgeSet = new Set();
        const edges = [];

        knowledge.forEach(entity => {
            entity.knowledgeRelations.forEach(rel => {
                const edgeId = `${entity.id}-${rel.id}`;
                if (!edgeSet.has(edgeId)) {
                    edgeSet.add(edgeId);
                    edges.push({
                        id: edgeId,
                        from: entity.id,
                        to: rel.id,
                        data: rel.rel
                    });
                }
            });
        });
        console.log("Edges", edges);

        setNodes(nodes);
        setEdges(edges);
    }, [knowledge]);

    const renderPopup = React.useCallback((popup) => {
        let data;
        try {
            data = JSON.parse(popup.data);
        } catch (error) {
            console.error("Invalid JSON data:", popup.data);
            return null;
        }

        return (
            <div style={{ position: 'absolute', left: popup.position.x, top: popup.position.y, backgroundColor: 'var(--primary-light)', transform: 'translate(-50%, -50%)', pointerEvents: 'none', margin: 0, padding: '.5rem', borderRadius: '.5rem' }}>
                {data.map((el, index) => {
                    const knowledgeFilteredData = knowledge.filter(knEl => knEl.id === el);
                    const knowledgeData = knowledgeFilteredData && knowledgeFilteredData.length > 0 ? knowledgeFilteredData[0] : null;
                    return knowledgeData ? (
                        <div key={"popup-key-" + el} style={index < data.length - 1 ? { marginBottom: '.5rem' } : {}}>
                            <p style={{ marginTop: 0, marginBottom: '.25rem', textTransform: 'capitalize', color: 'var(--text)', fontSize: 'x-small', fontWeight: 'bold' }}>{t('general.entities.' + knowledgeData.label)}</p>
                            <p style={{ margin: 0, color: 'var(--primary)', fontSize: 'smaller' }}>{knowledgeData.text}</p>
                        </div>
                    ) : null;
                })}
            </div>
        );
    }, [knowledge, t]);

    return (
        <div className={classes.Wrapper}>
            <Network
                nodes={nodes}
                edges={edges}
                renderPopup={(popup) => renderPopup(popup)}
                NodeComponent={CustomNodeContent}
            />
        </div>
    );
};

export default EntitiesNetwork;