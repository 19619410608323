import React from "react";
import { useTranslation } from "react-i18next";
import { SourceClass } from "../../utils/constants";
import Dropdown from "../Dropdown/Dropdown";
import classes from './SourceToAdd.module.css';

import MediaToAdd from "../MediaToAdd/MediaToAdd";

import ADD_ICON from '../../assets/images/add.svg';
import DELETE_ICON from '../../assets/images/delete.svg';


const SourceToAdd = React.forwardRef((props, ref) => {
  const {
    source,
    onEdit,
    onRemove
  } = props;

  const sourceTypeRef = React.useRef();
  const sourceTitleRef = React.useRef();
  const sourceUrlRef = React.useRef();
  const mediaRefs = React.useRef([]);
  mediaRefs.current = [];

  const { t } = useTranslation();

  const [url, setUrl] = React.useState(source.url || "");

  const setSourceTypesArrayForDropdown = React.useCallback(() => {
    return Object.values(SourceClass.sources)
      .map(s => {
        return {
          label: t("general.sources."+s.label),
          value: s.class
        }
      })
  }, [t]);

  const findSourceTypeIndexValue = React.useCallback((sourceType) => {
    const items = setSourceTypesArrayForDropdown();
    for(const [index, ent] of Object.entries(items)){
      if(sourceType === ent.value){
        return parseInt(index);
      }
    }
    return -1;
  }, [setSourceTypesArrayForDropdown]);

  const handleSetSourceType = React.useCallback((type) => {
    const newSource = { ...source };
    newSource.type = type;
    onEdit(newSource);
  }, [source, onEdit]);

  const handleSetSourceTitle = React.useCallback((title) => {
    const newSource = { ...source };
    newSource.title = title;
    onEdit(newSource);
  }, [source, onEdit]);

  const handleSetSourceUrl = React.useCallback((url) => {
    setUrl(url);
    const newSource = { ...source };
    newSource.url = url;
    onEdit(newSource);
  }, [source, onEdit]);

  const handleAddMedia = React.useCallback(() => {
    const newMedia = {
      title: "",
      file: null,
      fileData: null,
    }
    const newSource = { ...source };
    newSource.media.push(newMedia);
    onEdit(newSource);
  }, [source, onEdit]);

  const handleEditMedia = React.useCallback((newMedia, index) => {
    const newSource = { ...source };
    newSource.media[index] = newMedia;
    onEdit(newSource);
  }, [source, onEdit]);

  const handleRemoveMedia = React.useCallback((index) => {
    const newSource = { ...source };
    newSource.media.splice(index, 1);
    onEdit(newSource);
  }, [source, onEdit]);

  const handleRemoveSource = React.useCallback(() => {
    onRemove();
  }, [onRemove]);

  const addMediaRef = React.useCallback((m) => {
    if(m && m.checkData && !mediaRefs.current.includes(m)){
      mediaRefs.current.push(m);
    }
  }, [mediaRefs]);

  React.useImperativeHandle(ref, () => ({

    checkData(s) {
      let errorsFound = 0;
      if(typeof s !== "string"){
        if(!s.title){
          sourceTitleRef.current.classList.add(classes.InputError);
          errorsFound++;
        }
        if(!s.type){
          sourceTypeRef.current.classList.add(classes.InputError);
          errorsFound++;
        }
        if(!s.url){
          sourceUrlRef.current.classList.add(classes.InputError);
          errorsFound++;
        }
        //check Media titles
        for(const [i, m] of Object.entries(s.media)){
          const index = parseInt(i);
          const checkResult = mediaRefs.current[index].checkData(m);
          if(!checkResult){
            errorsFound++;
          }
        }
      }
      return errorsFound === 0;
    }

  }));

  return (
    <div ref={ref} className={classes.Container}>
      <div className={classes.SourceBox}>
        <div className={source.media && source.media.length > 0 ? classes.InfoColumn : [classes.InfoColumn, classes.InfoColumnWithRightPadding].join(" ")}>
          <div className={classes.SourceTypeRow}>
            <div
              ref={sourceTypeRef}
              className={classes.DropdownContainer}
              onClick={() => { sourceTypeRef.current.classList.remove(classes.InputError); }}
            >
              <Dropdown
                id={`${source.uuid}-source-type`}
                placeholder={t("author.addSources.selectSourceType")}
                value={findSourceTypeIndexValue(source.type)}
                elements={setSourceTypesArrayForDropdown()}
                onSelectValue={(value) => handleSetSourceType(value) }
              />
            </div>
          </div>
          <div className={classes.TitleRow}>
            <textarea
              ref={sourceTitleRef}
              className={classes.TitleTextArea}
              type="text"
              value={source.title}
              rows={3}
              placeholder={t("author.addSources.insertTitle")}
              onFocus={() => { sourceTitleRef.current.classList.remove(classes.InputError); }}
              onChange={(event) => handleSetSourceTitle(event.target.value)}
            />
          </div>
          <div className={classes.UrlRow}>
            <textarea
              ref={sourceUrlRef}
              className={classes.TitleTextArea}
              type="text"
              value={url}
              rows={2}
              placeholder={t("author.addSources.insertUrl")}
              onFocus={() => { sourceUrlRef.current.classList.remove(classes.InputError); }}
              onChange={(event) => handleSetSourceUrl(event.target.value)}
            />
          </div>
          <div className={classes.AddMediaRow}>
            <div className={classes.ClickableRowArea} onClick={() => handleAddMedia()}>
              <img className={classes.AddMediaIcon} src={ADD_ICON} alt="" />
              <p className={classes.AddMediaText}>{t("author.addSources.addMedia")}</p>
            </div>
          </div>
          <div className={source.media && source.media.length > 0 ? classes.DeleteSourceRowWithMediaMargin : classes.DeleteSourceRowWithoutMediaMargin}>
            <div className={classes.ClickableRowArea} onClick={() => handleRemoveSource()}>
              <img className={classes.DeleteSourceIcon} src={DELETE_ICON} alt="" />
              <p className={classes.DeleteSourceText}>{t("author.addSources.deleteSource")}</p>
            </div>
          </div>
        </div>
        {
          source.media && source.media.length > 0 &&
          <div className={classes.MediaColumn}>
            {
              source.media.map((med, index) => {
                return (
                  <div key={"source-"+source.uuid+"-media-"+index} className={classes.MediaContainer}>
                    <MediaToAdd
                      ref={(r) => addMediaRef(r)}
                      sourceUuid={source.uuid}
                      mediaIndex={index}
                      media={med}
                      onEditMedia={(newMedia) => handleEditMedia(newMedia, index)}
                      onRemoveMedia={() => handleRemoveMedia(index)}
                    />
                  </div>
                )
              })
            }
          </div>
        }
      </div>
      {
        source.id &&
        <div className={classes.DisableBoxContainer}></div>
      }
    </div>
  );

});

export default SourceToAdd;