import React from "react";
import { useTranslation } from "react-i18next";

import NarrationBox from "../NarrationBox/NarrationBox";

import classes from './NarrationNetwork.module.css';

import { commonProperties, supportedFileExtensions } from "../../utils/constants";

import DOCUMENT_ICON from "../../assets/images/file.svg";
import DOCUMENT_AUDIO_ICON from "../../assets/images/file.svg";
import DOCUMENT_VIDEO_ICON from "../../assets/images/file.svg";

const Marker = (props) => {
  return (
    <div className={props.className}>
      <svg viewBox="0 0 10 10">
        <path d="M0,0 L0,10 L10,5" fill="grey"></path>
      </svg>
    </div>
  )
}
const MultipleMarkerArrowStart = () => {
  return (
    <div style={{ display: 'flex', height: '2rem' }}>
      <svg width="100%" height="100%">
        <line x1="0" y1="0" x2="0" y2="100%" strokeWidth={2} stroke="grey" />
      </svg>
    </div>
  )
}
const MultipleMarkerArrowMiddle = () => {
  return (
    <div style={{ display: 'flex' }}>
      <svg width="100%" height="100%">
        <line x1="0" y1="0" x2="0" y2="100%" stroke="grey" strokeWidth={2} />
        <line x1="0" y1="50%" x2="100%" y2="50%" stroke="grey" strokeWidth={1} />
      </svg>
    </div>
  )
}
const MultipleMarkerArrowEnd = () => {
  return (
    <div style={{ display: 'flex' }}>
      <svg width="100%" height="100%">
        <line x1="0" y1="0" x2="0" y2="50%" stroke="grey" strokeWidth={2} />
        <line x1="0%" y1="50%" x2="100%" y2="50%" stroke="grey" strokeWidth={1} />
      </svg>
    </div>
  )
}

const vizTypes = {
  NARRATION: 'narration',
  KNOWLEDGE: 'knowledge',
  SOURCES: 'sources'
};

const NarrationNetwork = (props) => {
  
  const {
    selectedNarration,
    narrationAuthor,
    fragmentsNodes,
    changeVizType,
    onElementChange,
    onSourceMediaChange
  } = props;

  const { t } = useTranslation();
  const [hoveredFragment, setHoveredFragment] = React.useState();

  const setImageBasedOnSourceFile = React.useCallback((media) => {

    const mediaType = media[commonProperties.MEDIA_TYPE];
    if(mediaType){
      if(mediaType.includes(supportedFileExtensions.IMAGE.replace("data:", ""))){
        const imageUrl = media[commonProperties.ORIGINAL_URL];
        return imageUrl ? imageUrl : DOCUMENT_ICON;
      }
      else if(mediaType.includes(supportedFileExtensions.AUDIO.replace("data:", ""))){
        return DOCUMENT_AUDIO_ICON;
      }
      else if(mediaType.includes(supportedFileExtensions.VIDEO.replace("data:", ""))){
        return DOCUMENT_VIDEO_ICON;
      }
      else if(mediaType.includes(supportedFileExtensions.TEXT.replace("data:", ""))){
        return DOCUMENT_ICON;
      }
      else{
        return DOCUMENT_ICON;
      }
    }
    else if(media['o:original_url']) {
      return media['o:original_url'];
    }
    else{
      return DOCUMENT_ICON;
    }
  }, []);

  const [selectedSource, setSelectedSource] = React.useState(null);
  const [selectedElement, setSelectedElement] = React.useState(null);
  const [showNarrationSources, setShowNarrationSources] = React.useState(false);


  const handleImageClick = (elId, sourceId) => {
    onElementChange(elId);
    onSourceMediaChange(sourceId);
  };

  React.useEffect(() => {
    if(fragmentsNodes && fragmentsNodes.length > 0){
      
      const items = document.getElementsByClassName(classes.FragmentContainer);
      let maxHeight = 0;
      for(const el of items){
        if(el.clientHeight > maxHeight){
          maxHeight = el.clientHeight;
        }
      };
      for(const el of items){
        if(el.clientHeight < maxHeight){
          const marginTop = parseFloat((maxHeight - el.clientHeight) / 2);
          el.style.marginTop = marginTop + "px";
        }
        const i = el.getElementsByClassName(classes.FragmentText);
        if(i.length === 1){
          if(i[0].scrollHeight > i[0].clientHeight){
            i[0].classList.add(classes.OverflowFragmentText);
          }
        }

      };
    }
  }, [fragmentsNodes]);

  return (
      <div className={classes.Container}>
        <div className={classes.BoxContainer}>
          <div className={classes.LeftColumn}>
            <NarrationBox
                narration={selectedNarration}
                fragmentsNodes={fragmentsNodes}
                onFragmentHover={(fragment) => setHoveredFragment(fragment)}
            />
          </div>
          <div className={classes.RightColumn}>
            <div className={classes.CustomNetworkWrapper}>
              <div className={classes.NarrationIntestation}>
                { narrationAuthor &&
                    <div className={classes.IntestationFragment}>
                      <p className={classes.IntestationFragmentLabel}>{t('general.narrator')}</p>
                      <p className={classes.IntestationFragmentText}>{narrationAuthor}</p>
                    </div>
                }
              </div>
              <div className={classes.NetworkContainer}>
                {
                  fragmentsNodes.map((frag, index) => {
                    return (
                        <div
                            className={hoveredFragment === frag.id ? [classes.GroupContainer, classes.HoveredFragment].join(" ") : [classes.GroupContainer].join(" ")}
                            key={"custom-narration-fragment-container-"+frag.id}
                        >
                          <div className={classes.FragmentContainer}>
                            <div className={classes.Fragment}>
                              <p className={classes.FragmentLabel}>{frag.data.label}</p>
                              <p className={classes.FragmentText}>{frag.data.txt}</p>
                            </div>
                            { index < fragmentsNodes.length - 1 &&
                                <div className={classes.ArrowContainer}>
                                  <div className={classes.ArrowLine}></div>
                                  <Marker className={classes.ArrowMarker} />
                                </div>
                            }
                          </div>
                          <div className={classes.SourcesContainer}>
                            {
                                frag.data.sourceRefs.length > 0 &&
                                <div className={classes.Source}>
                                  <div className={classes.SourceArrow}>
                                    <MultipleMarkerArrowStart />
                                  </div>
                                </div>
                            }
                            {
                              frag.data.sourceRefs.map((el, ind) => {
                                if (el.sources && el.sources[0] && el.sources[0].media && el.sources[0].media[0]) {
                                  //console.log("el", JSON.stringify(el.sources[0].media[0]));
                                  console.log("src", setImageBasedOnSourceFile(el.sources[0].media[0]));
                                }
                                return (
                                    <div key={"narration-fragment-"+frag.id+"-source-"+ind} className={classes.Source}>
                                      <div className={classes.SourceArrow}>
                                        {
                                          ind < frag.data.sourceRefs.length - 1 ?
                                              <MultipleMarkerArrowMiddle /> :
                                              <MultipleMarkerArrowEnd />
                                        }
                                        <Marker className={classes.SourceArrowMarker}/>
                                      </div>
                                      <div className={classes.SourceBox}>
                                        <p className={classes.SourceBoxLabel}>{t("general.source")}</p>
                                        {
                                          <>
                                            {el.txt && <p className={classes.SourceBoxText} onClick={() => handleImageClick(el.id, null)}>{el.txt}</p>}
                                            {el.sources && el.sources[0] && el.sources[0].media && el.sources[0].media[0] &&
                                                <img
                                                    className={classes.SourceBoxMediaImage}
                                                    alt=""
                                                    src={setImageBasedOnSourceFile(el.sources[0].media[0])}
                                                    title={el.sources[0].media[0][commonProperties.SOURCE]}
                                                    onClick={() => handleImageClick(el.id, el.sources[0].id)}
                                                />
                                            }
                                          </>
                                        }
                                      </div>
                                    </div>
                                )
                              })
                            }
                          </div>
                        </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default NarrationNetwork;