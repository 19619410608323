import React from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { getKnowledgeItem } from "../../utils/omekaSManager";
import { foreignObjectBorders } from "../Graphs/NetworkGraph/CustomNode/CustomNode";
import Network from "../Network/Network";

import classes from './NarrationKnowledge.module.css';

import CLOSE_ICON from '../../assets/images/close.svg';

const getCustomNodeHeight = (data) => {
  const width = getCustomNodeWidth();
  const el = (<CustomNodeContent data={data} width={width} />);
  const div = document.createElement("div");
  const htmlString = ReactDOMServer.renderToStaticMarkup(el);
  div.innerHTML = htmlString;
  document.body.appendChild(div);
  const height = div.clientHeight;
  document.body.removeChild(div);
  return height + 2*foreignObjectBorders.y;
}

const getCustomNodeWidth = () => {
  return 450;
}

const CustomNodeContent = (props) => {

  const {
    id,
    width,
    hovered,
    data
  } = props;

  const ref = React.createRef();
  //const [isLongText, setIsLongText] = React.useState(false);

  /*React.useEffect(() => {
    if (ref.current.clientHeight < ref.current.scrollHeight) {
      setIsLongText(true);
    }
  }, [ref]);*/
  
  return (
    <div id={id} data-hover={hovered} className={classes.NodeContainer} style={{ width: width ? width : '100%' }}>
      <p className={classes.Label}>{data ? data.label : "-"}</p>
      <p ref={ref} className={[classes.Text, data.articleEntity ? classes.RegularText : classes.ItalicText, /*isLongText ? classes.OverflowText :*/ ''].join(" ")}>{ data ? data.txt : "-" }</p>
    </div>
  );
};

const NarrationKnowledge = (props) => {
  
  const {
    narration,
    knowledge,
    knowledgeLevel = 1
  } = props;

  const { t } = useTranslation();

  const [nodes, setNodes] = React.useState([]);
  const [edges, setEdges] = React.useState([]);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [selectedKnowledge, setSelectedKnowledge] = React.useState();

  const getLevelItems = React.useCallback((ids, isFirstLevel, isLastLevel = false) => {
    const levelNodes = [];
    const insertedNodesIds = [];
    const levelEdges = []
    const nextLevelNodes = [];

    for(const knId of ids){ 
      
      const knItem = getKnowledgeItem(knowledge, knId);
      
      if(knItem && !insertedNodesIds.includes(knItem.id)){
        const label = t('general.entities.'+knItem.label);
        const data = {
          articleEntity: isFirstLevel,
          label: label,
          txt: knItem.text,
          relations: knItem.knowledgeRelations
        };
        const nodeItem = {
          id: knItem.id,
          height: getCustomNodeHeight(data),
          width: getCustomNodeWidth(),
          data: data
        };
        levelNodes.push(nodeItem);
        insertedNodesIds.push(nodeItem.id);

        if(!isLastLevel){
          for(const nestedKn of knItem.knowledgeRelations){
            
            if(nestedKn.id && ![208, 16].includes(nestedKn.id)) {
              const edgeId = `${knItem.id}-${nestedKn.id}`
              const index = levelEdges.findIndex(el => { return el.id === edgeId });
              if(index === -1) {
                const edgeItem = {
                  id: edgeId,
                  from: knItem.id,
                  to: nestedKn.id,
                  data: JSON.stringify([{ rel: nestedKn.rel }])
                };
                
                levelEdges.push(edgeItem);
              }
              else{
                const newEdgeData = JSON.parse(levelEdges[index].data);
                newEdgeData.push({ rel: nestedKn.rel });
                levelEdges[index].data = JSON.stringify(newEdgeData);
              }

              if(!nextLevelNodes.includes(nestedKn.id)){
                nextLevelNodes.push(nestedKn.id);
              }
              
            }
          }
        }

      }
    }

    return {
      levelNodes,
      levelEdges,
      nextLevelNodes
    }
  }, [knowledge, t]);

  const onSelectKnowledgeNode = React.useCallback((node) => {
    setSelectedKnowledge(node);
    setDetailsOpen(true);
  }, []);

  React.useEffect(() => {
    let nodeItems = [];
    let edgeItems = [];
    
    const firstLevelKnowledgeIds = [];
    for(const frag of narration.fragments){
      for(const knId of frag.knowledgeEncoding){
        firstLevelKnowledgeIds.push(knId);
      }
    }
    
    let levelNodesIds = [ ...firstLevelKnowledgeIds ];

    for(let i=0; i<knowledgeLevel; i++) {
      const isLastLevel =  i === knowledgeLevel-1;
      const isFirstLevel =  i === 0;
      const levelItems = getLevelItems(levelNodesIds, isFirstLevel, isLastLevel);
      nodeItems = nodeItems.concat(levelItems.levelNodes);
      edgeItems = edgeItems.concat(levelItems.levelEdges);
      const alreadyTakenIds = nodeItems.map(el => { return el.id });
      levelNodesIds = levelItems.nextLevelNodes.filter(el => { return !alreadyTakenIds.includes(el) });
      console.log("ddd", i, levelItems);
    }

    setNodes(nodeItems);
    setEdges(edgeItems);

  }, [knowledgeLevel, narration, getLevelItems]);

  const renderPopup = React.useCallback((popup) => {

    const data = JSON.parse(popup.data);

    console.log("render popup", data);

    return (
      <div style={{ position: 'absolute', left: popup.position.x, top: popup.position.y, backgroundColor: 'var(--primary-opaque)', transform: 'translate(-50%, -50%)', pointerEvents: 'none', margin: 0, padding: '.5rem', borderRadius: '.5rem' }}>
        { 
          data.map(el => {
            return (
                <p
                    key={"popup-relation-unique-rel-"+el.rel}
                    style={{ margin: 0, fontSize: 'large', color: '#FFFFFF', fontWeight: 'bold' }}
                >
                  {t("general.relations."+el.rel)}
                </p>
            )
          })
        }
      </div>
    )
  }, [t]);

  return (
    <div className={classes.Wrapper}>
      <Network
        nodes={nodes}
        edges={edges}
        renderPopup={(data) => renderPopup(data)}
        onNodeClick={(node) => onSelectKnowledgeNode(node)}
        NodeComponent={CustomNodeContent}
        nodeColor="rgba(85, 86, 89, 0.5)"
        nodeHoverColor="rgba(85, 86, 89, 0.5)"
        edgeHoverColor="var(--primary-opaque)"
      />
      {
        detailsOpen && 
        <div className={classes.DetailsContainer}>
          <div className={classes.KnowledgeItemIntestationRow}>
            <p className={classes.KnowledgeItemLabel}>{selectedKnowledge.data.label}</p>
            <img 
              className={classes.CloseDetailsContainerIcon} 
              onClick={() => setDetailsOpen(false)}
              src={CLOSE_ICON}
              alt=""
            />
          </div>
          <p className={classes.KnowledgeItemTitle}>{selectedKnowledge.data.txt}</p>
          {/*<div className={classes.KnowledgeItemNarrationsContainer}>
            <p className={classes.KnowledgeItemNarrationIntestation}>{t('general.narration', { context: 'plural' })}</p>
            <p className={classes.KnowledgeItemNarrationTitle}>{selectedKnowledge.data.label}</p>
          </div>*/}
          <div className={classes.KnowledgeItemRelationsContainer}>
            <p className={classes.KnowledgeItemRelationIntestation}>{t('general.relation', { context: 'plural' })}</p>
            { 
              selectedKnowledge.data.relations && selectedKnowledge.data.relations.length > 0 &&
              selectedKnowledge.data.relations.map((r) => {
                const knItem = getKnowledgeItem(knowledge, r.id);
                return (
                  <div key={selectedKnowledge.id+'selected-knowledge-relation-'+r.id} className={classes.KnowledgeItemRelationRow}>
                    <p className={classes.KnowledgeItemRelationType}>{t('general.relations.'+r.rel)}:</p>
                    <p className={classes.KnowledgeItemRelationTitle}>{knItem ? knItem.text : '-'}</p>
                  </div>
                );
              })
            }
            { 
              (!selectedKnowledge.data.relations || selectedKnowledge.data.relations.length === 0) &&
              <p className={classes.KnowledgeItemRelationTitle}>-</p>
            }
          </div>
        </div>
      }
    </div>
  );

};

export default NarrationKnowledge;