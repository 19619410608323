import React from "react";

import classes from './EditNarration.module.css';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createJSONObjectForEdit, getEntitiesArrayData, getNarrationData, getSourcesArrayData } from "../../../utils/omekaSManager";

import { ArticleClass, commonProperties, ParagraphClass, roles, SourceReferenceClass } from "../../../utils/constants";

import AuthorComponent from "../../../components/AuthorComponent/AuthorComponent";
import { UserContext } from "../../../contexts/UserContext";

const axios = require('axios').default;


const EditNarration = () => {

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { userData, userLogged } = React.useContext(UserContext);
  
  const [data, setData] = React.useState([]);
  
  const loadData = React.useCallback(async (classes, properties, previousArticleData, newArticleData, alreadyExistingEntities, alreadyExistingSources) => {

    const feedback = [];

    try{

      const body = createJSONObjectForEdit(classes, properties, previousArticleData, newArticleData, alreadyExistingEntities, alreadyExistingSources);
      console.log("adding stuff - edit", body);
      
      if(body.narratorToEdit){
        if(body.narratorToEdit.id && body.narratorToEdit.json){
          try {
            console.log("updating stuff", body.narratorToEdit);
            const { data: resData } = await axios.patch("/api/items/"+body.narratorToEdit.id, body.narratorToEdit.json);
            console.log("editing stuff- NARRATOR ok", resData);
            feedback.push("OK");
          }
          catch(error){
            console.log("editing stuff - NARRATOR error", error);
            feedback.push("ERROR");
          };
        }
        else if(body.narratorToEdit.id && !body.narratorToEdit.json){
          try {
            const { data: resData } = await axios.delete("/api/items/"+body.narratorToEdit.id);
            console.log("deleting stuff- NARRATOR ok", resData);
            feedback.push("OK");
          }
          catch(error){
            console.log("deleting stuff - NARRATOR error", error);
            feedback.push("ERROR");
          };
        }
        else{
          //Add narrator
          let addedNarratorItemOmekaSId;
          let narratorPropertyId;
          try {
            narratorPropertyId = body.narratorToEdit.propertyId;
            const { data: resData } = await axios.post("/api/items", body.narratorToEdit.json);
            console.log("adding stuff- NARRATOR ok", resData);
            feedback.push("OK");
            addedNarratorItemOmekaSId = resData[commonProperties.ID];

            const { data: narrationResData } = await axios.get("/api/items/"+body.narrationId);
            const json = { ...narrationResData };
            if(addedNarratorItemOmekaSId && narratorPropertyId){
              json[ArticleClass.properties.narrator] = [
                {
                  [commonProperties.PROPERTY_ID] : narratorPropertyId,
                  [commonProperties.VALUE_RESOURCE_ID] : addedNarratorItemOmekaSId,
                  [commonProperties.RESOURCE_TYPE] : "resource"
                }
              ];
              const { data: resNarrData } = await axios.patch("/api/items/"+body.narrationId, json);
              console.log("editing stuff- NARRATION ok", resNarrData);
              feedback.push("OK");
            }
          }
          catch(error){
            console.log("adding stuff - NARRATOR error", error);
            feedback.push("ERROR");
          };
        }
      }

      if(body.titleToEdit){
        try {
          const { data: narrationResData } = await axios.get("/api/items/"+body.narrationId);
          const json = { ...narrationResData };
          json[ArticleClass.properties.dcTermsTitle] = [
            {
              [commonProperties.PROPERTY_ID] : 1,
              [commonProperties.VALUE] : body.titleToEdit.text,
              [commonProperties.RESOURCE_TYPE] : "literal"
            }
          ];
          json[ArticleClass.properties.title] = [
            {
              [commonProperties.PROPERTY_ID] : body.titleToEdit.propertyId,
              [commonProperties.VALUE] : body.titleToEdit.text,
              [commonProperties.RESOURCE_TYPE] : "literal"
            }
          ];
        
          const { data: resNarrData } = await axios.patch("/api/items/"+body.narrationId, json);
          console.log("editing stuff - NARRATION ok", resNarrData);
          feedback.push("OK");  
        }
        catch(error){
          console.log("adding stuff - NARRATION error", error);
          feedback.push("ERROR");
        };
      
      }

      if (body.descriptionToEdit) {
        try {
          const { data: narrationResData } = await axios.get("/api/items/"+body.narrationId);
          const json = { ...narrationResData };
          json[ArticleClass.properties.dcTermsDescription] = [
            {
              [commonProperties.PROPERTY_ID] : 4,
              [commonProperties.VALUE] : body.descriptionToEdit.text,
              [commonProperties.RESOURCE_TYPE] : "literal"
            }
          ];
          const { data: resNarrData } = await axios.patch("/api/items/"+body.narrationId, json);
          console.log("editing stuff - NARRATION ok", resNarrData);
          feedback.push("OK");
        }
        catch(error){
          console.log("adding stuff - NARRATION error", error);
          feedback.push("ERROR");
        };
      }

      const addedSourcesOmekaS = [];
      if(body.sourcesToEdit.length > 0){
    
        //adding sources elements
        for(const sourceData of body.sourcesToEdit){
          try {
            const formData = new FormData();
            formData.append("data", JSON.stringify(sourceData.json));
            for(const [i, f] of Object.entries(sourceData.filesArray)){
              const index = parseInt(i);
              formData.append(`file[${index}]`, f);
            }
            const { data: sData } = await axios.post("/api/itemsWithMedia", formData, { headers: { "Content-Type": `multipart/form-data; boundary=${formData._boundary}` }});
            console.log("adding stuff - SOURCES ok", sData);
            feedback.push("OK");
            addedSourcesOmekaS.push({uuid: sourceData.uuid, omekaSId: sData[commonProperties.ID] });
          }
          catch(error){
            console.log("adding stuff - SOURCES error", error);
            feedback.push("ERROR");
          };
        }
      }

      const addedAndEditedEntitiesOmekaSIds = [];
      if(body.entitiesToEdit.length > 0){
        //adding knowledge elements
        for(const entData of body.entitiesToEdit){
          if(entData.action === "ADD"){
            try {
              const { data: eData } = await axios.post("/api/items", entData.json);
              console.log("adding stuff- ENTITIES ok", eData);
              addedAndEditedEntitiesOmekaSIds.push({ fragmentUuid: entData.fragmentUuid, uuid: entData.uuid, omekaSId: eData[commonProperties.ID] });
              feedback.push("OK"); 
            }
            catch(error){
              console.log("adding stuff - ENTITIES error", error);
              feedback.push("ERROR");
            }
          }
          else if(entData.action === "EDIT"){
            try {
              const { data: eData } = await axios.patch("/api/items/"+entData.id, entData.json);
              console.log("editing stuff- ENTITIES ok", eData);
              addedAndEditedEntitiesOmekaSIds.push({ fragmentUuid: entData.fragmentUuid, uuid: entData.uuid, omekaSId: entData.id });
              feedback.push("OK"); 
            }
            catch(error){
              console.log("editing stuff - ENTITIES error", error);
              feedback.push("ERROR");
            }
          }
          else if(entData.action === "-"){
            addedAndEditedEntitiesOmekaSIds.push({ fragmentUuid: entData.fragmentUuid, uuid: entData.uuid, omekaSId: entData.id });
          }
          else{}
        }      
      }

      if(body.relationsToEdit.length > 0){
        for(const rel of body.relationsToEdit){
          let from;
          const fromArray = alreadyExistingEntities.filter(el => { return el.id && el.uuid === rel.from });
          if(fromArray.length === 1){
            from = { id: fromArray[0].id };
          }
          else{
            const fromArray2 = addedAndEditedEntitiesOmekaSIds.filter(el => { return el.uuid === rel.from });
            if(fromArray2.length === 1){
              from = { id: fromArray2[0].omekaSId };
            }
          }
          let toEl;
          const toArray = alreadyExistingEntities.filter(el => { return el.id && el.uuid === rel.to });
          if(toArray.length === 1){
            toEl = { id: toArray[0].id };
          }
          else{
            const toArray2 = addedAndEditedEntitiesOmekaSIds.filter(el => { return el.uuid === rel.to });
            if(toArray2.length === 1){
              toEl = { id: toArray2[0].omekaSId };
            }
          }

          const { data: entityItemFrom } = await axios.get("/api/items/"+from.id);
          if(rel.action === "ADD"){
            const json = { ...entityItemFrom }
            if(json[rel.property]){
              json[rel.property].push({
                [commonProperties.PROPERTY_ID]: rel.relationId,
                [commonProperties.VALUE_RESOURCE_ID]: toEl.id,
                [commonProperties.RESOURCE_TYPE]: "resource"
              })
            }
            else{
              json[rel.property] = [{
                [commonProperties.PROPERTY_ID]: rel.relationId,
                [commonProperties.VALUE_RESOURCE_ID]: toEl.id,
                [commonProperties.RESOURCE_TYPE]: "resource"
              }];
            }
            try {
              const { data: resData } = await axios.patch("/api/items/"+from.id, json);
              console.log("editing stuff- ENTITY (for relation) ok", resData);
              feedback.push("OK");
            }
            catch(error){
              console.log("editing stuff - ENTITY (for relation) error", error);
              feedback.push("ERROR");
            };
          }
          else if(rel.action === "EDIT"){
            let oldToEl;
            const toArray = alreadyExistingEntities.filter(el => { return el.uuid === rel.oldTo });
            if(toArray.length === 1){
              oldToEl = toArray[0];
            }
            else{
              const toArray2 = addedAndEditedEntitiesOmekaSIds.filter(el => { return el.uuid === rel.oldTo });
              if(toArray2.length === 1){
                oldToEl = toArray2[0];
              }
            }
          
            const json = { ...entityItemFrom }
            const currentRelationArray = json[rel.property];
            const newRelationArray = [];
          
            for(const obj of currentRelationArray){
              if(obj[commonProperties.VALUE_RESOURCE_ID] === oldToEl.id){
                newRelationArray.push({
                  [commonProperties.PROPERTY_ID]: rel.relationId,
                  [commonProperties.VALUE_RESOURCE_ID]: toEl.id,
                  [commonProperties.RESOURCE_TYPE]: "resource"
                })
              }
              else{
                newRelationArray.push(obj);
              }
            }
          
            json[rel.property] = [ ...newRelationArray ];

            try {
              const { data: resData } = await axios.patch("/api/items/"+from.id, json);
              console.log("editing stuff- ENTITY (for relation) ok", resData);
              feedback.push("OK");
            }
            catch(error){
              console.log("editing stuff - ENTITY (for relation) error", error);
              feedback.push("ERROR");
            };
          }
          else if(rel.action === "REMOVE"){
            const json = { ...entityItemFrom }
            const currentRelationArray = json[rel.property];
            const newRelationArray = currentRelationArray.filter(el => { return el[commonProperties.VALUE_RESOURCE_ID] !== toEl.id });
            json[rel.property] = [ ...newRelationArray ];

            try {
              const { data: resData } = await axios.patch("/api/items/"+from.id, json);
              console.log("editing stuff- ENTITY (for relation) ok", resData);
              feedback.push("OK");
            }
            catch(error){
              console.log("editing stuff - ENTITY (for relation) error", error);
              feedback.push("ERROR");
            };
          }
          else {}
        }
      }

      const addedAndEditedSourceReferencesOmekaS = [];
      if(body.sourceReferencesToEdit.length > 0){
        for(const sf of body.sourceReferencesToEdit){
          if(sf.action === "ADD"){
            try {
              const json = { ...sf.json };
              for(let i=0; i<json[SourceReferenceClass.properties.references].length; i++){
                const sourceUuid = json[SourceReferenceClass.properties.references][i];
                const sourceIndex1 = addedSourcesOmekaS.findIndex(el => { return el.uuid === sourceUuid });
                const sourceIndex2 = alreadyExistingSources.findIndex(el => { return el.uuid === sourceUuid });
                if(sourceIndex1 !== -1 || sourceIndex2 !== -1){
                  json[SourceReferenceClass.properties.references][i] = {
                    [commonProperties.PROPERTY_ID]: sf.referencesID,
                    [commonProperties.VALUE_RESOURCE_ID]: sourceIndex1 !== -1 ? addedSourcesOmekaS[sourceIndex1].omekaSId : alreadyExistingSources[sourceIndex2].id,
                    [commonProperties.RESOURCE_TYPE]: "resource"
                  }
                }
              }
              const { data: sfData } = await axios.post("/api/items", json);
              console.log("adding stuff - SOURCE REFERENCE ok", sfData);
              addedAndEditedSourceReferencesOmekaS.push({ fragmentUuid: sf.fragmentUuid, uuid: sf.uuid, omekaSId: sfData[commonProperties.ID] });
              feedback.push("OK");
            }
            catch(error){
              console.log("adding stuff - ENTITIES error", error);
              feedback.push("ERROR");
            };
          }
          else if(sf.action === "EDIT"){
            try {
              const json = { ...sf.json };
              for(let i=0; i<json[SourceReferenceClass.properties.references].length; i++){
                const sourceUuid = json[SourceReferenceClass.properties.references][i];
                const sourceIndex1 = addedSourcesOmekaS.findIndex(el => { return el.uuid === sourceUuid });
                const sourceIndex2 = alreadyExistingSources.findIndex(el => { return el.uuid === sourceUuid });
                if(sourceIndex1 !== -1 || sourceIndex2 !== -1){
                  json[SourceReferenceClass.properties.references][i] = {
                    [commonProperties.PROPERTY_ID]: sf.referencesID,
                    [commonProperties.VALUE_RESOURCE_ID]: sourceIndex1 !== -1 ? addedSourcesOmekaS[sourceIndex1].omekaSId : alreadyExistingSources[sourceIndex2].id,
                    [commonProperties.RESOURCE_TYPE]: "resource"
                  }
                }
              }
              const { data: sfData } = await axios.patch("/api/items/"+sf.id, json);
              addedAndEditedSourceReferencesOmekaS.push({ fragmentUuid: sf.fragmentUuid, uuid: sf.uuid, omekaSId: sf.id });
              console.log("editing stuff- SOURCE REFERENCE ok", sfData);
              feedback.push("OK");
            }
            catch(error){
              console.log("editing stuff - SOURCE REFERENCE error", error);
              feedback.push("ERROR");
            };
          }
          else if(sf.action === "REMOVE"){
            try {
              const { data: sfData } = await axios.delete("/api/items/"+sf.id);
              console.log("deleting stuff- SOURCE REFERENCE ok", sfData);
              feedback.push("OK");
            }
            catch(error){
              console.log("deleting stuff - SOURCE REFERENCE error", error);
              feedback.push("ERROR");
            };
          }
          else{}
        }
      }

      const addedAndEditedParagraphsOmekaSIds = [];
      if(body.fragmentsToEdit.length > 0){
        for(const f of body.fragmentsToEdit){
          if(f.action === "ADD"){
            try {
              const json = { ...f.json };
              for(let i=0; i<json[ParagraphClass.properties.sourceReference].length; i++){
                const sourceRefUuid = json[ParagraphClass.properties.sourceReference][i];
                let sourceRef;
                const sourceArray = addedAndEditedSourceReferencesOmekaS.filter(el => { return el.uuid === sourceRefUuid });
                if(sourceArray.length === 1){
                  sourceRef = { id: sourceArray[0].omekaSId };
                }
                else{
                  const sourceArray2 = alreadyExistingSources.filter(el => { return el.id && el.uuid === sourceRefUuid });
                  if(sourceArray2.length === 1){
                    sourceRef = { id: sourceArray[0].id };
                  }
                }
                if(sourceRef){
                  json[ParagraphClass.properties.sourceReference][i] = {
                    [commonProperties.PROPERTY_ID]: f.sourceRefPropertyId,
                    [commonProperties.VALUE_RESOURCE_ID]: sourceRef.id,
                    [commonProperties.RESOURCE_TYPE]: "resource"
                  }
                }
              }
              for(let i=0; i<json[ParagraphClass.properties.knowledgeEncoding].length; i++){
                const knowledgeEncUuid = json[ParagraphClass.properties.knowledgeEncoding][i];
                let knowledgeEl;
                const knowledgeArray = addedAndEditedEntitiesOmekaSIds.filter(el => { return el.uuid === knowledgeEncUuid });
                if(knowledgeArray.length === 1){
                  knowledgeEl = { id: knowledgeArray[0].omekaSId };
                }
                else{
                  const knowledgeArray2 = alreadyExistingEntities.filter(el => { return el.id && el.uuid === knowledgeEncUuid });
                  if(knowledgeArray2.length === 1){
                    knowledgeEl = { id: knowledgeArray2[0].id };
                  }
                }
                if(knowledgeEl){
                  json[ParagraphClass.properties.knowledgeEncoding][i] = {
                    [commonProperties.PROPERTY_ID]: f.knowledgeEncodingPropertyId,
                    [commonProperties.VALUE_RESOURCE_ID]: knowledgeEl.id,
                    [commonProperties.RESOURCE_TYPE]: "resource"
                  }
                }
              }
              const { data: sfData } = await axios.post("/api/items", json);
              console.log("adding stuff- PARAGRAPH ok", sfData);
              addedAndEditedParagraphsOmekaSIds.push({ uuid: f.uuid, action: "ADD", property: f.property, propertyID: f.propertyID, omekaSId: sfData[commonProperties.ID] });
              feedback.push("OK");
            }
            catch(error){
              console.log("adding stuff - PARAGRAPH error", error);
              feedback.push("ERROR");
            };
          }
          else if(f.action === "EDIT"){
            try {
              const json = { ...f.json };
              for(let i=0; i<json[ParagraphClass.properties.sourceReference].length; i++){
                const sourceRefUuid = json[ParagraphClass.properties.sourceReference][i];
                let sourceRef;
                const sourceArray = addedAndEditedSourceReferencesOmekaS.filter(el => { return el.uuid === sourceRefUuid });
                if(sourceArray.length === 1){
                  sourceRef = { id: sourceArray[0].omekaSId };
                }
                else{
                  const sourceArray2 = alreadyExistingSources.filter(el => { return el.id && el.uuid === sourceRefUuid });
                  if(sourceArray2.length === 1){
                    sourceRef = { id: sourceArray[0].id };
                  }
                }
                if(sourceRef){
                  json[ParagraphClass.properties.sourceReference][i] = {
                    [commonProperties.PROPERTY_ID]: f.sourceRefPropertyId,
                    [commonProperties.VALUE_RESOURCE_ID]: sourceRef.id,
                    [commonProperties.RESOURCE_TYPE]: "resource"
                  }
                }
              }
              for(let i=0; i<json[ParagraphClass.properties.knowledgeEncoding].length; i++){
                const knowledgeEncUuid = json[ParagraphClass.properties.knowledgeEncoding][i];
                let knowledgeEl;
                const knowledgeArray = addedAndEditedEntitiesOmekaSIds.filter(el => { return el.uuid === knowledgeEncUuid });
                if(knowledgeArray.length === 1){
                  knowledgeEl = { id: knowledgeArray[0].omekaSId };
                }
                else{
                  const knowledgeArray2 = alreadyExistingEntities.filter(el => { return el.id && el.uuid === knowledgeEncUuid });
                  if(knowledgeArray2.length === 1){
                    knowledgeEl = { id: knowledgeArray2[0].id };
                  }
                }
                if(knowledgeEl){
                  json[ParagraphClass.properties.knowledgeEncoding][i] = {
                    [commonProperties.PROPERTY_ID]: f.knowledgeEncodingPropertyId,
                    [commonProperties.VALUE_RESOURCE_ID]: knowledgeEl.id,
                    [commonProperties.RESOURCE_TYPE]: "resource"
                  }
                }
              }
              const { data: fData } = await axios.patch("/api/items/"+f.id, json);
              addedAndEditedParagraphsOmekaSIds.push({ uuid: f.uuid, omekaSId: f.id });
              console.log("editing stuff- PARAGRAPH ok", fData);
              feedback.push("OK");
            }
            catch(error){
              console.log("editing stuff - PARAGRAPH error", error);
              feedback.push("ERROR");
            };
          }
          else if(f.action === "REMOVE"){
            try {
              const { data: fData } = await axios.delete("/api/items/"+f.id);
              console.log("deleting stuff- PARAGRAPH ok", fData);
              feedback.push("OK");
            }
            catch(error){
              console.log("deleting stuff - PARAGRAPH error", error);
              feedback.push("ERROR");
            };
          }
          else{}
        }
        //update next and previous
        for(const f of body.fragmentsToEdit){
          if(f.action === "ADD" || f.action === "EDIT"){
            try {
              const fArray = addedAndEditedParagraphsOmekaSIds.filter(el => { return el.uuid === f.uuid })
              if(fArray.length === 1){
                const fId = fArray[0].omekaSId;
                const { data: fData } = await axios.get("/api/items/"+fId);
                const json = { ...fData };
                const fNextArray = addedAndEditedParagraphsOmekaSIds.filter(el => { return el.uuid === f.nextUuid })
                const fPreviousArray = addedAndEditedParagraphsOmekaSIds.filter(el => { return el.uuid === f.previousUuid })
                if(fNextArray.length === 1){
                  json[ParagraphClass.properties.next] = [{
                    [commonProperties.PROPERTY_ID]: f.nextPropertyId,
                    [commonProperties.VALUE_RESOURCE_ID]: fNextArray[0].omekaSId,
                    [commonProperties.RESOURCE_TYPE]: "resource"
                  }];
                }
                if(fPreviousArray.length === 1){
                  json[ParagraphClass.properties.previous] = [{
                    [commonProperties.PROPERTY_ID]: f.previousPropertyId,
                    [commonProperties.VALUE_RESOURCE_ID]: fPreviousArray[0].omekaSId,
                    [commonProperties.RESOURCE_TYPE]: "resource"
                  }];
                }
                const { data: f2Data } = await axios.patch("/api/items/"+fId, json);
                console.log("editing stuff- PARAGRAPH ok", f2Data);
                feedback.push("OK");
              }
            }
            catch(error){
              console.log("editing stuff - PARAGRAPH error", error);
              feedback.push("ERROR");
            };
          }
        }
      }

      const fragmentsToAddToNarration = [ ...addedAndEditedParagraphsOmekaSIds.filter(el => { return el.action === "ADD" })]
      if(fragmentsToAddToNarration.length > 0){
        try {
          const { data: narrationResData } = await axios.get("/api/items/"+body.narrationId);
          const json = { ...narrationResData };
          for(const f of fragmentsToAddToNarration){
            if(json[f.property]){
              json[f.property].push(
                {
                  [commonProperties.PROPERTY_ID] : f.propertyID,
                  [commonProperties.VALUE_RESOURCE_ID] : f.omekaSId,
                  [commonProperties.RESOURCE_TYPE] : "resource"
                }
              );
            }
            else{
              json[f.property] = [
                {
                  [commonProperties.PROPERTY_ID] : f.propertyID,
                  [commonProperties.VALUE_RESOURCE_ID] : f.omekaSId,
                  [commonProperties.RESOURCE_TYPE] : "resource"
                }
              ];
            }
          }
          const { data: resNarrData } = await axios.patch("/api/items/"+body.narrationId, json);
          console.log("editing stuff - NARRATION ok", resNarrData);
          feedback.push("OK");  
        }
        catch(error){
          console.log("adding stuff - NARRATION error", error);
          feedback.push("ERROR");
        };
      }

    }
    catch(e){
      console.log("Error in creating or saving data", e);
      feedback.push("ERROR");
    }

    return feedback;
    
  }, []);

  const deleteData = React.useCallback(async (previousArticleData) => {

    const feedback = [];

    try{

      //Delete fragments
      if(previousArticleData.fragments){
        for(const f of previousArticleData.fragments){
          
          //Delete source references
          for(const sr of f.sourceReferences){
            try {
              const { data: resData } = await axios.delete("/api/items/"+sr.id);
              console.log("deleting stuff- SOURCE REFERENCE ok", resData);
              feedback.push("OK");
            }
            catch(error){
              console.log("deleting stuff - SOURCE REFERENCE error", error);
              feedback.push("ERROR");
            };
          }

          //Delete fragment
          try {
            const { data: resData } = await axios.delete("/api/items/"+f.id);
            console.log("deleting stuff- PARAGRAPH ok", resData);
            feedback.push("OK");
          }
          catch(error){
            console.log("deleting stuff - PARAGRAPH error", error);
            feedback.push("ERROR");
          };
        }
      }

      //Delete narrator
      if(previousArticleData.narrator){
        try {
          const { data: resData } = await axios.delete("/api/items/"+previousArticleData.narrator.id);
          console.log("deleting stuff- NARRATOR ok", resData);
          feedback.push("OK");
        }
        catch(error){
          console.log("deleting stuff - NARRATOR error", error);
          feedback.push("ERROR");
        };
      }

      //Delete article
      try {
        const { data: resData } = await axios.delete("/api/items/"+previousArticleData.id);
        console.log("deleting stuff- ARTICLE ok", resData);
        feedback.push("OK");
      }
      catch(error){
        console.log("deleting stuff - ARTICLE error", error);
        feedback.push("ERROR");
      };
      
    }
    catch(e){
      console.log("Error in deleting data", e);
      feedback.push("ERROR");
    }

    return feedback;
    
  }, []);

  const redirectTo = React.useCallback((url, state = null) => {
    if(state){
      navigate(url, { state: { ...state } });
    }
    else{
      navigate(url);
    }
  }, [navigate]);
  
  React.useEffect(() => {
    if(userData){
      if(userLogged){
        axios.get("/api/classesAndProperties").then(
          (res) => {
            console.log("OK CLASSES AND PROPERTIES", res.data);
            const d = { ...res.data };
            axios.get("/api/data").then(
              (r) => {
                console.log("OK Data", r.data);
                if(id){
                  d.alreadyExistingEntities = getEntitiesArrayData(r.data.items, r.data.media);
                  d.alreadyExistingSources = getSourcesArrayData(r.data.items, r.data.media);
                  d.article = getNarrationData(parseInt(id), r.data.items, r.data.media, d.alreadyExistingEntities, d.alreadyExistingSources);
                  if(d.article){
                    if(userData.role === roles.ADMINISTRATOR || d.article.owner === userData.omekasId){
                      setData(d);
                    }
                    else{
                      redirectTo("/visualization/narrations");
                    }
                  }
                  else{
                    redirectTo("/visualization/narrations");
                  }
                }
                else{
                  redirectTo("/visualization/narrations");
                }
              },
              (e) => {
                console.log("ERROR on retriving data", e);
              }
            );
          },
          (error) => {
            console.log("ERROR on retriving classes and properties", error);
          }
        );
      }
      else{
        redirectTo("/visualization/narrations");
      }
    }
  }, [id, userData, userLogged, redirectTo]);

  return (
    <AuthorComponent 
      isEdit={true}
      data={data}
      relationDropdownDisabled={true}
      saveData={(classes, properties, previousArticleData, newArticleData, alreadyExistingEntities, alreadyExistingSources) => loadData(classes, properties, previousArticleData, newArticleData, alreadyExistingEntities, alreadyExistingSources)}
      removeData={(previousArticleData) => deleteData(previousArticleData)}
    />
  );
};

export default EditNarration;