import React from "react";
import { useTranslation } from "react-i18next";
import { commonProperties, supportedFileExtensions } from "../../utils/constants";
import classes from './NarrationSources.module.css';
import { useNavigate } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';

import DOCUMENT_ICON from "../../assets/images/file.svg";
import DOCUMENT_AUDIO_ICON from "../../assets/images/file.svg";
import DOCUMENT_VIDEO_ICON from "../../assets/images/file.svg";

const NarrationSources = (props) => {
  const axios = require('axios').default;
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      'pdfjs-dist/build/pdf.worker.min.mjs',
      import.meta.url,
  ).toString();

  const {
    narration,
    selectedElement,
    selectedSourceMedia,
    changeVizType
  } = props;

  const navigate = useNavigate();

  const navigateToNarration = (narrationId) => {
    const vizTypes = {
      NARRATION: 'narration',
      KNOWLEDGE: 'knowledge',
      SOURCES: 'sources'
    };
    props.changeVizType(vizTypes.NARRATION);
    navigate(`/visualization/narrations/${narrationId}`);
  };

  const { t } = useTranslation();

  const [popupOpen, setPopupOpen] = React.useState(false);
  const [selectedSourceRef, setSelectedSourceRef] = React.useState();
  const [selectedSource, setSelectedSource] = React.useState();
  const [selectedMedia, setselectedMedia] = React.useState();

  const [popupText, setPopupText] = React.useState();
  const [popupReferences, setPopupReferences] = React.useState();
  const openTextPopup = React.useCallback((s) => {
    axios.get("/api/linkedResources/"+ s.id ).then(
        async (res) => {
          let referencesList = [];
          if (res.data["http://purl.org/hsg/hsg:references"]) {
            const reference = res.data["http://purl.org/hsg/hsg:references"];
            //console.log("Reference", reference);
            const fonteRelazionata = await axios.get("/api/linkedResources/"+reference[0].value_resource_id);
            //console.log("Fonte relazionata", JSON.stringify(fonteRelazionata.data));
            if (fonteRelazionata.data["@reverse"]["http://purl.org/hsg/hsg:references"]) {
              const references = fonteRelazionata.data["@reverse"]["http://purl.org/hsg/hsg:references"];
              for (let ref of references) {
                let id = ref["@id"].split("/").pop();
                let response = await axios.get("/api/linkedResources/" + id);
                let titleRiferimento = response.data["o:title"];
                let titleParagrafo = response.data["@reverse"]["http://purl.org/hsg/narration:has_source_reference-f"][0]["o:title"];
                let idParagrafo = response.data["@reverse"]["http://purl.org/hsg/narration:has_source_reference-f"][0]["@id"].split("/").pop();
                let titleFonte = fonteRelazionata.data["dcterms:title"][0]["@value"];
                const paragrafoRelazionato = await axios.get("/api/linkedResources/"+idParagrafo);
                //console.log("Paragrafo relazionato", JSON.stringify(paragrafoRelazionato.data));
                let idNarrazione = null;
                let titoloNarrazione = null;
                let keys = Object.keys(paragrafoRelazionato.data["@reverse"]);
                for (let key of keys) {
                  if (key.startsWith("http://purl.org/hsg/narration:")) {
                    if (paragrafoRelazionato.data["@reverse"][key]) {
                      idNarrazione = paragrafoRelazionato.data["@reverse"][key][0]["@id"].split("/").pop();
                      titoloNarrazione = paragrafoRelazionato.data["@reverse"][key][0]["o:title"];
                    }
                  }
                }
                referencesList.push({
                  titleRiferimento: titleRiferimento,
                  titleParagrafo: titleParagrafo,
                  idParagrafo: idParagrafo,
                  titleFonte: titleFonte,
                  idNarrazione: idNarrazione,
                  titoloNarrazione: titoloNarrazione,
                });
              }
            }
          }
          setPopupText(referencesList);
        },
        (error) => {
          console.log("ERROR", error);
        }
    );
  }, []);

  const openPopup = React.useCallback(async (sourceReference, source, media) => {
    let referencesList = [];
    const res = await axios.get("/api/linkedResources/"+ sourceReference.id );
    if (res.data["http://purl.org/hsg/hsg:references"]) {
      const reference = res.data["http://purl.org/hsg/hsg:references"];
      const fonteRelazionata = await axios.get("/api/linkedResources/"+reference[0].value_resource_id);
      if (fonteRelazionata.data["@reverse"]["http://purl.org/hsg/hsg:references"]) {
        const references = fonteRelazionata.data["@reverse"]["http://purl.org/hsg/hsg:references"];
        for (let ref of references) {
          let id = ref["@id"].split("/").pop();
          let response = await axios.get("/api/linkedResources/" + id);
          let titleRiferimento = response.data["o:title"];
          let titleParagrafo = response.data["@reverse"]["http://purl.org/hsg/narration:has_source_reference-f"][0]["o:title"];
          let idParagrafo = response.data["@reverse"]["http://purl.org/hsg/narration:has_source_reference-f"][0]["@id"].split("/").pop();
          let titleFonte = fonteRelazionata.data["dcterms:title"][0]["@value"];
          const paragrafoRelazionato = await axios.get("/api/linkedResources/"+idParagrafo);
          let idNarrazione = null;
          let titoloNarrazione = null;
          let keys = Object.keys(paragrafoRelazionato.data["@reverse"]);
          for (let key of keys) {
            if (key.startsWith("http://purl.org/hsg/narration:")) {
              if (paragrafoRelazionato.data["@reverse"][key]) {
                idNarrazione = paragrafoRelazionato.data["@reverse"][key][0]["@id"].split("/").pop();
                titoloNarrazione = paragrafoRelazionato.data["@reverse"][key][0]["o:title"];
              }
            }
          }
          referencesList.push({
            titleRiferimento: titleRiferimento,
            titleParagrafo: titleParagrafo,
            idParagrafo: idParagrafo,
            titleFonte: titleFonte,
            idNarrazione: idNarrazione,
            titoloNarrazione: titoloNarrazione,
          });
        }
      }
    }
    setPopupReferences(referencesList);
    setselectedMedia(media);
    setSelectedSource(source);
    setSelectedSourceRef(sourceReference);
    setPopupOpen(true);
  }, []);

  const closePopup = React.useCallback(() => {
    setselectedMedia();
    setSelectedSource();
    setSelectedSourceRef();
    setPopupOpen(false);
  }, []);

  const openMedia = React.useCallback((sourceReference, source, media) => {
    const mediaType = media[commonProperties.MEDIA_TYPE];
    const originalUrl = media[commonProperties.ORIGINAL_URL];
    if(mediaType.includes(supportedFileExtensions.IMAGE.replace("data:", ""))){
      openPopup(sourceReference, source, media);
    }
    else{
      //window.open(originalUrl, "_blank");
      openPopup(sourceReference, source, media);
    }
  }, [openPopup]);

  const setImageBasedOnSourceFile = React.useCallback((media) => {

    const mediaType = media[commonProperties.MEDIA_TYPE];
    if(mediaType){
      if(mediaType.includes(supportedFileExtensions.IMAGE.replace("data:", ""))){
        const imageUrl = media[commonProperties.ORIGINAL_URL];
        return imageUrl ? imageUrl : DOCUMENT_ICON;
      }
      else if(mediaType.includes(supportedFileExtensions.AUDIO.replace("data:", ""))){
        return DOCUMENT_AUDIO_ICON;
      }
      else if(mediaType.includes(supportedFileExtensions.VIDEO.replace("data:", ""))){
        return DOCUMENT_VIDEO_ICON;
      }
      else if(mediaType.includes(supportedFileExtensions.TEXT.replace("data:", ""))){
        return DOCUMENT_ICON;
      }
      else{
        return DOCUMENT_ICON;
      }
    }
    else{
      return DOCUMENT_ICON;
    }
  }, []);

  React.useEffect(() => {
    //console.log(selectedMedia, selectedSource, selectedSourceRef)
    console.log("selectedMedia", selectedMedia);
  }, [selectedMedia, selectedSource, selectedSourceRef])

  return (
      <div className={classes.Wrapper}>
        <div className={classes.ContentContainer}>
          {
            narration.fragments.map(el => {
              if(el.sourceReferences.length > 0){
                return (
                    <div
                        key={"narration-"+narration.id+"-source-box-fragment-"+el.id}
                        className={classes.FragmentContainer}
                    >
                      <p className={classes.FragmentTitle}>{t('general.fragments.'+el.label)}</p>
                      <div className={classes.SourcesContainer}>
                        {
                          el.sourceReferences.map((s, index) => {
                            if(s.sources) {
                              return s.sources.map((src, index) => {
                                return (
                                    <div
                                        key={"narration-"+narration.id+"-source-box-fragment-"+el.id+"-source-"+index}
                                        className={classes.SourceBox}
                                    >
                                      <p className={classes.SourceBoxLabel}>{t("general.source")}</p>
                                      <p className={classes.SourceBoxText}>{src.title}</p>
                                      <div className={classes.SourceBoxMediaRow}>
                                        {
                                          src.media.map((m, i) => {
                                            return (
                                                <div
                                                    key={"narration-"+narration.id+"-source-box-fragment-"+el.id+"-source-"+index+"-media-"+i}
                                                    className={classes.SourceBoxMediaContainer}
                                                    onClick={() => openMedia(s, src, m)}
                                                >
                                                  <img
                                                      className={classes.SourceBoxMediaImage}
                                                      alt=""
                                                      src={setImageBasedOnSourceFile(m)}
                                                      title={m[commonProperties.SOURCE]}
                                                  />
                                                </div>
                                            )
                                          })
                                        }
                                      </div>
                                    </div>
                                );
                              })
                            }else{
                              return (
                                  <div
                                      key={"narration-" + narration.id + "-source-box-fragment-" + el.id + "-source-" + index}
                                      className={classes.SourceBox}
                                      style={selectedElement === s.id ? {border: '2px solid #F81A5C'} : {}}
                                      onClick={() => openTextPopup(s)}
                                  >
                                    <p className={classes.SourceBoxLabel}>{t("general.source")}</p>
                                    <p className={classes.SourceBoxText}>{s.txt}</p>
                                  </div>
                              );
                            }
                          })
                        }
                      </div>
                    </div>
                )
              } else {
                return (
                    <div
                        key={"narration-" + narration.id + "-source-box-fragment-" + el.id}
                        className={classes.FragmentContainer}
                    >
                      <p className={classes.FragmentTitle}>{t('general.fragments.'+el.label)}</p>
                    </div>
                );
              }
            })
          }
        </div>
        {
            popupOpen &&
            <div className={classes.PopupContainer}>
              <div className={classes.PopupBoxContainer}>
                {selectedMedia[commonProperties.MEDIA_TYPE].includes('application/pdf') ? (
                    <Document file={selectedMedia[commonProperties.ORIGINAL_URL]} onClick={() => window.open(selectedMedia[commonProperties.ORIGINAL_URL], "_blank")}>
                      <Page pageNumber={1} />
                    </Document>
                ) : selectedMedia[commonProperties.ORIGINAL_URL] && (
                    <img className={classes.PopupImage} alt="" src={selectedMedia[commonProperties.ORIGINAL_URL]}/>
                )}
                <div className={classes.PopupInfoColumn}>
                  <div className={classes.ClosePopupContainer}>
                    <p className={classes.ClosePopupIcon} onClick={() => closePopup()}>X</p>
                  </div>
                  <div className={classes.PopupInfoContainer}>
                    <div className={classes.PopupInfoRow}>
                      <p className={classes.PopupInfoLabel}>Riferimento Fonte:</p>
                      <p className={classes.PopupInfoText}>{selectedSourceRef.txt ? selectedSourceRef.txt : "-"}</p>
                    </div>
                    <div className={classes.PopupInfoRow}>
                      <p className={classes.PopupInfoLabel}>Fonte:</p>
                      <p className={classes.PopupInfoText}>{selectedSource.title ? selectedSource.title : "-"}</p>
                    </div>
                    <div>
                      <p className={classes.PopupInfoLabel}>Narrazioni relazionati alla fonte:</p>
                      <table className={classes.PopupTable}>
                        <thead>
                        <tr>
                          <th>Narrazione</th>
                          <th>Paragrafo</th>
                          <th>Riferimento</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          popupReferences.map((item, index) => {
                            return (
                                <tr key={index} onClick={() => setPopupText(null)}>
                                  <td onClick={(e) => {
                                    e.stopPropagation();
                                    navigateToNarration(item.idNarrazione);
                                    setPopupText(null);
                                  }}>{item.titoloNarrazione}</td>
                                  <td>{item.titleParagrafo}</td>
                                  <td>{item.titleRiferimento}</td>
                                </tr>
                            );
                          })
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
        {
            popupText &&
            <div className={classes.PopupContainer}>
              <div className={classes.PopupBoxContainer}>
                <div>
                  <div>
                    <p className={classes.PopupInfoLabel}>Titolo:</p>
                    <p className={classes.PopupInfoText}>
                      {popupText && popupText[0] && popupText[0].titleFonte ? popupText[0].titleFonte : "Nessuna fonte relazionata"}
                    </p>
                  </div>
                  <div>
                    <p className={classes.PopupInfoLabel}>Narrazioni relazionati alla fonte:</p>
                    <table className={classes.PopupTable}>
                      <thead>
                      <tr>
                        <th>Narrazione</th>
                        <th>Paragrafo</th>
                        <th>Riferimento</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        popupText.map((item, index) => {
                          return (
                              <tr key={index} onClick={() => setPopupText(null)}>
                                <td onClick={(e) => {
                                  e.stopPropagation();
                                  navigateToNarration(item.idNarrazione);
                                  setPopupText(null);
                                }}>{item.titoloNarrazione}</td>
                                <td>{item.titleParagrafo}</td>
                                <td>{item.titleRiferimento}</td>
                              </tr>
                          );
                        })
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={classes.ClosePopupContainer}>
                  <p className={classes.ClosePopupIcon} onClick={() => setPopupText(null)}>X</p>
                </div>
              </div>
            </div>
        }
      </div>
  );

};

export default NarrationSources;