import React from "react";
import { useTranslation } from "react-i18next";

import DELETE_ENTITY from '../../assets/images/delete.svg';
import EDIT_ENTITY from '../../assets/images/edit.svg';

import classes from './InsertedEntities.module.css';

const InsertedEntities = (props) => {
  
  const {
    entities,
    existingEntities,
    insertedEntities,
    setHoveredEntity,
    onRemoveEntity,
    onEditEntity
  } = props;

  const { t } = useTranslation();

  const areThereAnyEntitiesOfType = React.useCallback((entity) => {
    const filteredEntities = insertedEntities.filter(e => {
      let entityObj;
      if(!e.entity){
        const filteredItems = existingEntities.filter(el => { return el.uuid === e.uuid });
        if(filteredItems && filteredItems.length === 1){
          entityObj = filteredItems[0]; 
        }
      }
      else{
        entityObj = e;
      }
      return entityObj && entityObj.entity === entity 
    });
    return filteredEntities.length > 0;
  }, [insertedEntities, existingEntities]);

  return (
    <div>
      {
        entities.map(e => {
          if(areThereAnyEntitiesOfType(e.label)){
            return (
              <div key={"inserted-entity-group-"+e.label} className={classes.InsertedEntitiesType}>
                <p className={classes.InsertedEntityTitle}>{t('general.entities.'+e.label)}</p>
                {
                  insertedEntities.map((insertedEntity, entityIndex) => {
                    let entityObj;
                    if(!insertedEntity.entity){
                      const filteredItems = existingEntities.filter(el => { return el.uuid === insertedEntity.uuid });
                      if(filteredItems && filteredItems.length === 1){
                        entityObj = filteredItems[0];
                      }
                    }
                    else{
                      entityObj = insertedEntity;
                    }
                    
                    if( entityObj && entityObj.entity === e.label){
                      return (
                        <div
                          key={"entity-"+entityObj.uuid}
                          style={{ position: 'relative' }}
                        >
                          <div
                            className={classes.InsertedEntityBody}
                            onMouseEnter={() => {
                              if(entityObj.selection.anchorOffset != null && entityObj.selection.focusOffset != null){
                                setHoveredEntity("entity-"+entityObj.uuid)
                              }
                            }}
                            onMouseLeave={() => setHoveredEntity()}
                          >
                            <p
                              title={entityObj.selection.text}
                              className={(entityObj.selection.anchorOffset == null || entityObj.selection.focusOffset == null) ? [classes.InsertedEntityText, classes.Custom].join(" ") : [classes.InsertedEntityText].join(" ")}
                            >
                              {entityObj.selection.text}
                            </p>
                            <div className={classes.IconContainer}>
                              <img
                                  src={EDIT_ENTITY} alt=""
                                  className={classes.EditInsertedEntityIcon}
                                  onClick={() => onEditEntity(entityIndex, entityObj)}
                              />
                              <img
                                  src={DELETE_ENTITY} alt=""
                                  className={classes.DeleteInsertedEntityIcon}
                                  onClick={() => onRemoveEntity(entityIndex)}
                              />
                            </div>
                          </div>
                          {
                            /*
                            entityObj.id &&
                            <div className={classes.DisableBoxContainer}></div>
                            */
                          }
                        </div>
                      );
                    }
                    else{
                      return null;
                    }
                  })
                }
              </div>
            )
          }
          else{
            return null;
          }
        })
      }
    </div>
  );

};

export default InsertedEntities;