import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect, useCallback } from "react";
import LeafletMap from "../LeafletMap/LeafletMap";
import classes from './Map.module.css';
import { useTranslation } from "react-i18next";
import L from 'leaflet';

const sortLocationsByCoordinates = (a, b) => {
  if (a.location.lat < b.location.lat) {
    return -1;
  } else if (a.location.lat > b.location.lat) {
    return 1;
  } else {
    if (a.location.lng < b.location.lng) {
      return -1;
    } else if (a.location.lng > b.location.lng) {
      return 1;
    } else {
      return 0;
    }
  }
};

const createLocationEdges = (narrations, knowledge) => {
  console.log("narrations", narrations);
  const edges = [];
  for (let i = 0; i < narrations.length - 1; i++) {
    const narr1 = narrations[i];
    const knowledgeEncoding1 = narr1.narrKnowledgeEncoding || [];
    for (let j = i + 1; j < narrations.length; j++) {
      const narr2 = narrations[j];
      const knowledgeEncoding2 = narr2.narrKnowledgeEncoding || [];
      const commonKnowledge = knowledgeEncoding1.filter(ke =>
        knowledgeEncoding2.includes(ke)
      );
      if (commonKnowledge.length > 0) {

        const knowledgeDetails = commonKnowledge.map(keId =>
            knowledge.find(k => k.id === keId)
        ).filter(k => k !== undefined);

        edges.push({
          id: `${narr1.id}-${narr2.id}`,
          from: narr1.id,
          to: narr2.id,
          knowledge: knowledgeDetails,
          narrationFrom: narr1,
          narrationTo: narr2
        });
      }
    }
  }
  console.log("edges", edges);
  console.log("knowledge", knowledge);
  return edges;
};

const Map = forwardRef((props, ref) => {
  const {
    narrations,
    knowledge,
    onSelectNarration,
    change,
    onMapClick
  } = props;

  const mapRef = useRef();

  useImperativeHandle(ref, () => ({
    centerMap(locs) {
      const coords = locs.map((el) => L.latLng(el.lat, el.lng));
      console.log("locs", locs);

      const updatedMarkers = markersArray.map(marker => {
        const isHighlighted = locs.some(loc =>
          marker.entityId === loc.entityId
        );
        return {
          ...marker,
          selected: isHighlighted,
        };
      });

      setMarkersArray(updatedMarkers);

      if (mapRef.current) {
        const mapInstance = mapRef.current.getMapInstance();
        const bounds = L.latLngBounds(coords);
        console.log("bounds", bounds);
        //mapInstance.fitBounds(bounds, { animate: true });
        setTimeout(() => {
          mapInstance.invalidateSize();
          console.log("current zoom", mapInstance.getZoom());
          const newZoom = mapInstance.getZoom() + 9;
          console.log("new zoom", newZoom);
          mapInstance.setView(bounds.getCenter(), newZoom);
        }, 200);
      }
    }
  }));

  const { t } = useTranslation();

  const [markersArray, setMarkersArray] = useState([]);
  const [edges, setEdges] = useState([]);
  const [showEdges, setShowEdges] = useState(true);

  const manageNarrations = useCallback((narrationsArray) => {
    const items = [];
    console.log("narrationsArray", narrationsArray);
    for (const narr of narrationsArray) {
      let narrKnowledgeEncoding = [];
      for (const f of narr.fragments) {
        if (f.knowledgeEncoding.length > 0) {
          narrKnowledgeEncoding = narrKnowledgeEncoding.concat(f.knowledgeEncoding);
        }
      }
      items.push({
        ...narr,
        narrKnowledgeEncoding: narrKnowledgeEncoding
      });
    }
    return items;
  }, []);

  useEffect(() => {
    if (narrations && narrations.length > 0 && knowledge && knowledge.length > 0) {
      const processedNarrations = manageNarrations(narrations);
      const items = [];
      let allLocations = [];
      for (const n of processedNarrations) {
        const knowledgeEncoding = n.narrKnowledgeEncoding || [];
        for (const loc of n.locations) {
          if (loc && loc.lat !== undefined && loc.lng !== undefined) {
            allLocations = allLocations.concat({
              narration: {
                title: n.text,
                id: n.id,
                description: n.description,
                knowledgeEncoding: knowledgeEncoding
              },
              location: loc
            });
          }
        }
      }
      allLocations.sort(sortLocationsByCoordinates);

      const locs = allLocations.reduce((previousValue, currentValue) => {
        const index = previousValue.findIndex(el => (el.location.lat === currentValue.location.lat) && (el.location.lng === currentValue.location.lng) && (el.location.entityId !== currentValue.location.entityId));
        if (index === -1) {
          return previousValue.concat({
            entity: currentValue.location.entity,
            narrations: [currentValue.narration],
            location: currentValue.location
          });
        } else {
          previousValue[index].narrations.push(currentValue.narration);
          return previousValue;
        }
      }, []);

      console.log("locs", locs);
      for (const l of locs) {
        const popup = document.createElement("div");
        popup.style = "font-family: 'Gotham'";
        const narrationLabel = L.DomUtil.create("p", "popup-narration-label", popup);
        narrationLabel.style = "margin: 0; color: var(--text-light); font-size: x-small; font-weight: bold; text-transform: uppercase;";
        narrationLabel.textContent = l.entity;
        const narrationsContainer = L.DomUtil.create("div", "popup-narrations-content", popup);
        for (const n of l.narrations) {
          const narr = L.DomUtil.create("p", "popup-narration-text", narrationsContainer);
          narr.style = "margin: .5rem 0 0 0; color: var(--text); font-size: small; font-weight: bold; cursor: pointer;";
          narr.textContent = n.title;
          narr.ondblclick = () => onSelectNarration(n);

          const description = L.DomUtil.create("p", "popup-narration-description", narrationsContainer);
          description.style = "margin: .5rem 0 0 0; color: var(--text); font-size: small;";
          description.textContent = n.description;
        }
        items.push({
          popup: popup,
          coordinates: {
            lat: l.location.lat,
            lng: l.location.lng
          },
          entityId: l.location.entityId,
          narration: l.narrations[0]?.id || null
        });
      }
      setMarkersArray(items);
      setEdges(createLocationEdges(processedNarrations, knowledge));
    } else {
      setMarkersArray([]);
      setEdges([]);
    }
  }, [narrations, knowledge, onSelectNarration, t, manageNarrations]);

  return (
    <div className={classes.Wrapper}>
      <div className={classes.CheckboxContainer}>
        <label>
          <input
            type="checkbox"
            checked={showEdges}
            onChange={() => setShowEdges(!showEdges)}
          />
          Show Edges
        </label>
      </div>
      <LeafletMap
        ref={mapRef}
        markers={markersArray}
        edges={showEdges ? edges : []}
        zIndex={0}
        onSelectNarration={onSelectNarration}
        change={change}
        onMapClick={onMapClick}

      />
    </div>
  );
});

export default Map;