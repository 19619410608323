import React from "react";

import * as GLOBAL_STYLES from '../../utils/stylesConstants';

import classes from './BottomNavigation.module.css';

import { useTranslation } from "react-i18next";


const margin = ".5rem";

const icons = {
  'narration-network': require('../../assets/images/narrationsActive.svg').default,
  'entity-network': require('../../assets/images/knowledgeActive.svg').default,
  'source-network': require('../../assets/images/file.svg').default,
};

const BottomNavigation = (props) => {

  const {
    activeCode,
    buttons,
    style,
    change
  } = props;

  const { t } = useTranslation();

  const [hovered, setHovered] = React.useState(false);

  const gridTemplateColumns = () => {
    return buttons.map(() => { return '1fr' }).join(" ");
  }

  const findButtonIndex = (code) => {
    if (code === 'narrations-network' || code === 'entities-network' || code === 'sources-network') {
        code = 'network';
    }
    for(const [index, btn] of buttons.entries()){
      if(btn.code === code) {
          return index;
      }
    }
    return -1;
  }

  const setActiveBtnPosition = () => {
    const index = findButtonIndex(activeCode);
    const fractPart = 100 / (buttons.length * 2);
    return (index * 2 * fractPart + fractPart).toFixed(2) + '%';
  }

  const setActiveBtnWidth = () => {
    const fractPart = 100 / (buttons.length);
    return `calc(${fractPart.toFixed(2)}% - ${margin})`;
  }

  return (
      <div style={{ ...style }}>
        <div className={classes.BtnContainer} style={{ gridTemplateColumns: gridTemplateColumns() }}>
          <div className={classes.ActiveBtn} style={{ left: setActiveBtnPosition(), width: setActiveBtnWidth(), backgroundColor: GLOBAL_STYLES.primaryColor }}></div>
          {buttons.map(btn => (
              <div
                  key={btn.code}
                  className={classes.ButtonWrapper}
                  onMouseEnter={() => btn.code === 'network' && setHovered(true)}
                  onMouseLeave={() => btn.code === 'network' && setHovered(false)}
              >
                <p
                    className={classes.Button}
                    style={{ color: activeCode === btn.code ? '#fff' : GLOBAL_STYLES.textPrimaryColor }}
                    onClick={() => change(btn.code)}
                >
                  {btn.label}
                </p>
                {hovered && btn.code === 'network' && (
                    <div className={classes.AdditionalButtons}>
                      <div className={classes.AdditionalButton} onClick={() => change('narrations-network')}>
                        <img src={icons["narration-network"]} alt="Sub 1" className={classes.Icon}/>
                        <span className={classes.ButtonLabel}>{t("general.narration_plural")}</span>
                      </div>
                      <div className={classes.AdditionalButton} onClick={() => change('entities-network')}>
                        <img src={icons["entity-network"]} alt="Sub 2" className={classes.Icon}/>
                        <span className={classes.ButtonLabel}>{t("general.entity")}</span>
                      </div>
                      <div className={classes.AdditionalButton} onClick={() => change('sources-network')}>
                        <img src={icons["source-network"]} alt="Sub 3" className={classes.Icon}/>
                        <span className={classes.ButtonLabel}>{t("general.source_plural")}</span>
                      </div>
                    </div>
                )}
              </div>
          ))}
        </div>
      </div>
  );

};

export default BottomNavigation;