import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import * as omekaSManager from "../../utils/omekaSManager";
import { routes } from '../../components/Router/RouterManager';
import Narrations from "./Narrations/Narrations";
import Narration from "./Narration/Narration";

const axios = require('axios').default;

const routeBaseName = routes.VISUALIZATION.child;

const Visualization = () => {
  
  const [narrations, setNarrations] = React.useState([]);
  const [knowledge, setKnowledge] = React.useState([]);
  const [media, setMedia] = React.useState([]);

  const manageData = React.useCallback((data) => {
    setNarrations(omekaSManager.getNarrationsArray(data.items, data.media));
    setKnowledge(omekaSManager.getKnowledgeArray(data.items, data.media));
    setMedia(omekaSManager.getMediaArray(data.media));
  }, []);

  React.useEffect(() => {
    axios.get("/api/data").then(
      (res) => {
        //console.log("EEE", res);
        manageData(res.data);
      },
      (error) => {
        console.log("ERROR", error);
      }
    );
  }, [manageData]);

  return (
    <Routes>
      <Route path={routeBaseName.NARRATIONS.name} exact element={<Narrations narrations={narrations} knowledge={knowledge} media={media} />} />
      <Route path={routeBaseName.NARRATIONS.name+"/:id"} exact element={<Narration narrations={narrations} knowledge={knowledge} media={media} />} />
      <Route path="*" element={<Navigate to={routeBaseName.NARRATIONS.name} />} />
    </Routes>
  );
};

export default Visualization;