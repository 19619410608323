import React from 'react';
import Slider from "react-slick";
import classes from './imageCarousel.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

const ImageCarousel = ({ images, onImageSelect }) => {
    const handleDragStart = (e) => e.preventDefault();

    const handleImageClick = (id) => {
        //cerca items relazionati a media
        const selectedImage = images.find(image => image.id === id);
        onImageSelect(selectedImage);
    }

    const items = images.map((image, index) => (
        <img
            key={index}
            src={image.url}
            onDragStart={handleDragStart}
            role="presentation"
            className={classes.image}
            alt="carouselImage"
            onDoubleClick={() => handleImageClick(image.id)}
            style={{ objectFit: 'contain' }}
        />
    ));


    const settings = {
        className: "",
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 0,
        variableWidth: true,
        rows: 1,

    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {items}
            </Slider>
        </div>
    );
}

export default ImageCarousel;