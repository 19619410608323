import React, { useState, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import pdfIcon from '../../assets/images/file.svg'; // Import the SVG file

const SourcesNetwork = (props) => {
    const { narrations, media } = props;
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [images, setImages] = useState({});
    const [tooltip, setTooltip] = useState({ visible: false, imageUrl: '', x: 0, y: 0 });

    const typeToColor = {
        "Image": "#7f8c8d", // muted blue-gray
        "Book": "#c0392b", // muted red
        "Webpage": "#27ae60", // muted green
        "Generic_source": "#d35400", // muted orange
        "Article": "#8e44ad", // muted purple
    };
    console.log("narrations", narrations);

    const Legend = ({ typeToColor }) => {
        return (
            <div style={{ position: 'absolute', top: 100, right: 10, backgroundColor: 'white', padding: '10px', border: '1px solid black', zIndex: 10000 }}>
                <h4>Legend</h4>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {Object.entries(typeToColor).map(([type, color]) => (
                        <li key={type} style={{ marginBottom: '5px' }}>
                            <span style={{ display: 'inline-block', width: '20px', height: '20px', backgroundColor: color, marginRight: '10px' }}></span>
                            {type}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    useEffect(() => {
        const nodes = [];
        const edges = [];
        const mediaMap = new Map();
        const imageCache = {};

        // Preload images
        media.forEach((mediaItem) => {
            let imageUrl = '';
            if (mediaItem['o:media_type'] === 'image/jpeg') {
                imageUrl = mediaItem['o:original_url'];
            } else if (mediaItem['o:media_type'] === 'application/pdf') {
                imageUrl = pdfIcon; // Use the imported SVG for PDF files
            }
            if (imageUrl) {
                const img = new Image();
                img.src = imageUrl;
                imageCache[mediaItem['o:id']] = img;
            }
        });

        // Collect all media items and create nodes from narrations
        narrations?.forEach((narration) => {
            narration.fragments?.forEach((fragment) => {
                fragment.sourceReferences?.forEach((sourceReference) => {
                    sourceReference.sources?.forEach((source) => {
                        //console.log("source", source);
                        source.media?.forEach((mediaItem) => {
                            if (!mediaMap.has(mediaItem['o:id'])) {
                                const title = mediaItem['dcterms:title']?.[0]?.['@value'] || 'No Title';
                                let image = '';
                                if (mediaItem['o:media_type'] === 'image/jpeg') {
                                    image = mediaItem['o:original_url'];
                                } else if (mediaItem['o:media_type'] === 'application/pdf') {
                                    image = pdfIcon; // Use the imported SVG for PDF files
                                } else {
                                    image = pdfIcon; // Use the imported SVG for other media types
                                }
                                nodes.push({
                                    id: mediaItem['o:id'],
                                    name: title,
                                    image: image,
                                    type: source.type // Add type to node
                                });
                                mediaMap.set(mediaItem['o:id'], mediaItem);
                            }
                        });
                    });
                });
            });
        });

        narrations?.forEach((narration) => {
            const mediaInNarration = new Set();
            narration.fragments?.forEach((fragment) => {
                fragment.sourceReferences?.forEach((sourceReference) => {
                    sourceReference.sources?.forEach((source) => {
                        source.media?.forEach((mediaItem) => {
                            mediaInNarration.add(mediaItem['o:id']);
                        });
                    });
                });
            });

            const mediaArray = Array.from(mediaInNarration);
            for (let i = 0; i < mediaArray.length; i++) {
                for (let j = i + 1; j < mediaArray.length; j++) {
                    edges.push({
                        source: mediaArray[i],
                        target: mediaArray[j],
                        text: narration.text // Add narration text to the edge
                    });
                }
            }
        });

        setNodes(nodes);
        setEdges(edges);
        setImages(imageCache);
    }, [narrations, media]);

    const myData = {
        nodes: nodes,
        links: edges
    };

    const drawNode = (node, ctx, globalScale) => {
        const img = images[node.id];
        const size = 50 / globalScale; // Adjust the size of the image
        const nodeSize = 60 / globalScale; // Adjust the size of the node

        // Draw the node background
        ctx.fillStyle = typeToColor[node.type] || 'lightgray'; // Use color based on type
        ctx.beginPath();
        ctx.arc(node.x, node.y, nodeSize / 2, 0, 2 * Math.PI, false);
        ctx.fill();

        // Draw the image
        if (img) {
            ctx.save();
            ctx.beginPath();
            ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI, false);
            ctx.clip();
            ctx.drawImage(img, node.x - size / 2, node.y - size / 2, size, size);
            ctx.restore();
        }

        // Draw the node label
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = `${12 / globalScale}px Arial`; // Adjust the font size
        //ctx.fillText(node.name, node.x, node.y + nodeSize / 2 + 10 / globalScale); // Adjust the position of the text
    };

    return (
        <div>
            <Legend typeToColor={typeToColor} />
            <ForceGraph2D
                width={window.innerWidth}
                height={window.innerHeight}
                graphData={myData}
                nodeCanvasObject={drawNode}
                linkColor={() => 'black'}
                onNodeClick={(node) => {
                    setTooltip({
                        visible: true,
                        imageUrl: node.image,
                        x: 100,
                        y: 100
                    });
                }}
                onLinkClick={(link) => {
                    setTooltip({
                        visible: true,
                        text: link.text,
                        x: 50,
                        y: 100
                    });
                }}
            />
            {tooltip.visible && (
                <div style={{
                    position: 'absolute',
                    top: 100,
                    left: 50,
                    backgroundColor: 'white',
                    padding: '5px',
                    border: '1px solid black',
                    zIndex: 9999
                }}>
                    <button onClick={() => setTooltip({ visible: false, imageUrl: '', text: '', x: 0, y: 0 })} style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        background: 'red',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer'
                    }}>X</button>
                    {tooltip.imageUrl && <img src={tooltip.imageUrl} alt="Node" style={{ width: '200px', height: '200px' }} />}
                    {tooltip.text && <p>{tooltip.text}</p>}
                </div>
            )}
        </div>
    );
};

export default SourcesNetwork;