import React from 'react';
import classes from './ImagePopup.module.css';
import { useNavigate } from 'react-router-dom';
import pdfIcon from '../PdfCarousel/pdf-icon.png';

const ImagePopup = ({ popupOpen, selectedMedia, closePopup }) => {
    const navigate = useNavigate();

    if (!popupOpen) {
        return null;
    }

    const isPdf = selectedMedia.url.endsWith('.pdf');

    return (
        <div className={classes.PopupContainer}>
            <div className={classes.PopupBoxContainer}>
                {isPdf ? (
                    <iframe
                        className={classes.PopupPdf}
                        src={selectedMedia.url}
                        title={selectedMedia.title}
                    />
                ) : (
                    <div className={classes.ImageAndTitleContainer}>
                        <img className={classes.PopupImage} alt="" src={selectedMedia.url}/>
                        <h2>{selectedMedia.title}</h2>
                        <h3>Narrations:</h3>
                        {selectedMedia.narrations.map((narration, index) => (
                            <p key={index} onClick={() => {
                                navigate(`/visualization/narrations/${narration.id}`);
                            }}>{narration.text}</p>
                        )).reduce((prev, curr, index) => {
                            return index === 0 ? [curr] : [...prev, <hr />, curr]
                        }, [])}
                    </div>
                )}
                <div className={classes.PopupInfoColumn}>
                    <div className={classes.ClosePopupContainer}>
                        <p className={classes.ClosePopupIcon} onClick={() => {
                            closePopup();
                        }}>x</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImagePopup;